import { MutableRefObject, useEffect } from "react";

// this hook is used to resize textarea based on the content height
const useTextareaAutoHeight = (ref?: MutableRefObject<HTMLTextAreaElement | null>) => {
  useEffect(() => {
    if (!ref || !ref.current) return;
    const listener = () => {
      if (ref.current) {
        ref.current.style.padding = "0px";
        ref.current.style.height = ref.current.scrollHeight + "px";
        ref.current.style.removeProperty("padding");
      }
    };
    listener();
    ref.current.addEventListener("input", listener);
  }, [ref]);
};
export default useTextareaAutoHeight;
