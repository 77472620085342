import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import clsx from "clsx";
import React from "react";

const PopoverWrapper = ({
  children,
  className,
  classNameContainer,
  minWidth,
}: {
  children: React.ReactNode;
  className?: string;
  classNameContainer?: string;
  minWidth?: number;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();
  const handleRef = React.useCallback((el: HTMLElement | null) => {
    setAnchorEl(el);
  }, []);

  return (
    <div className={classNameContainer} ref={handleRef}>
      {anchorEl && (
        <Popper className={clsx("z-50", className)} open anchorEl={anchorEl} placement="bottom-start">
          <Paper className="!max-w-full" elevation={1} sx={{ minWidth: minWidth }}>
            {children}
          </Paper>
        </Popper>
      )}
    </div>
  );
};

export default PopoverWrapper;
