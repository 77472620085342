import {
  GridCellModes,
  GridCellParams,
  GridEditMode,
  GridEditModes,
  useGridApiContext
} from "@mui/x-data-grid-premium";
import clsx from "clsx";
import { useCallback } from "react";
import CellGroup from "components/CellGroup";
import { TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";
import { CellType, DensityType } from "utils/constants";
import InputCellGroup from "components/CellGroup/CellGroupHandler/InputCellGroup";
import { getCellProps } from "components/CellGroup/utils";
import TextCellGroup from "components/CellGroup/CellGroupHandler/TextCellGroup";
import { getSizeBasedInRowDensity } from "./utils";

const CellGroupEdit = ({
  params,
  col,
  rowDensity,
  handleEmailClick,
  handleCellDetailActionClick,
  idx,
  onRecordEdit,
  allColumns,
  editMode,
  isFormulaTable = false,
  inDetailView = false,
  onEditDisabled
}: {
  params: GridCellParams;
  col: TableColumnType;
  rowDensity?: { type: DensityType };
  handleEmailClick: (record?: RecordItem) => void;
  handleCellDetailActionClick: (record: RecordItem, tablePath: string) => void;
  idx: number;
  onRecordEdit: (
    value: any,
    row: RecordItem,
    col: TableColumnType,
    additionalValues?: { textContent?: string }
  ) => void;
  allColumns: TableColumnType[];
  editMode: GridEditMode;
  isFormulaTable?: boolean;
  inDetailView?: boolean;
  row?: RecordItem;
  onEditDisabled?: (row: RecordItem, col: TableColumnType) => void;
}) => {
  const apiRef = useGridApiContext();
  const additionalCellProps = getCellProps(col.type);

  const handleEdit = useCallback(
    async (value: any, additionalValues?: { textContent?: string }) => {
      if (apiRef.current.getCellMode(params.id, params.field) !== GridCellModes.Edit) {
        apiRef.current.startCellEditMode({ id: params.id, field: params.field, initialValue: value });
      } else {
        await apiRef.current.setEditCellValue({ id: params.id, field: params.field, value });
      }
      onRecordEdit(value, params.row, col, additionalValues);
    },
    [apiRef, params, col, onRecordEdit]
  );

  const handleCellGroupEdit = useCallback(
    async (value: any) => {
      handleEdit(value);
    },
    [handleEdit]
  );

  if (col.type === CellType.LONG_TEXT) {
    return <TextCellGroup value={params.row[col.id]} inGrid onEdit={handleEdit} autoFocus {...additionalCellProps} />;
  }

  if (
    isFormulaTable &&
    (col.type === CellType.CURRENCY || col.type === CellType.NUMBER || col.type === CellType.PERCENT)
  ) {
    return (
      <InputCellGroup
        value={params.row[col.id]}
        inGrid
        onEdit={handleEdit}
        autoFocus
        ignoreValidation
        isFormula={col.isFormula}
        {...additionalCellProps}
      />
    );
  }

  return (
    <CellGroup
      column={col}
      type={col.type}
      value={params.row[col.id]}
      className={clsx("my-4 w-full", !idx ? "cursor-pointer" : "")}
      classNameContainer="w-full"
      size={rowDensity ? getSizeBasedInRowDensity(rowDensity?.type) : undefined}
      onEmailClick={handleEmailClick}
      onCellDetailActionClick={handleCellDetailActionClick}
      isMultiple={col.isMultiple}
      row={params.row}
      onEdit={handleCellGroupEdit}
      isEditable
      inGrid
      setEditMode
      isFocused={editMode === GridEditModes.Cell}
      autoFocus={editMode === GridEditModes.Cell}
      allColumns={allColumns}
      columnWidth={params.colDef.computedWidth}
      expandSelect={editMode === GridEditModes.Cell}
      isInTable={!inDetailView}
      onEditDisabled={onEditDisabled}
    />
  );
};

export default CellGroupEdit;
