import { useQuery } from "@tanstack/react-query";
import { getActionByName } from "lib/adminApi";
import { ActionResponse } from "types/apiTypes";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import useCurrentUser from "./useCurrentUser";

const useActionByName = (name: string, hookOptions?: any) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const { data, isLoading, refetch } = useQuery<ActionResponse>({
    queryKey: ["ui_action", name, currentUser?.org_id],
    queryFn: () => getActionByName(supabaseClient, name, currentUser?.org_id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    const: true,
    enabled: !!name,
    ...hookOptions
  });
  return {
    data: data?.data,
    isLoading,
    refetch
  };
};

export default useActionByName;
