import { GridRow, GridRowProps } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { ColumnRule, TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";
import { ALL_COLUMNS } from "utils/constants";
import { GRID_SELECT_COL_DEF_INITIAL, getRuleStyles } from "./utils";

type CustomGridRowProps = GridRowProps & {
  conditionalFormatting?: ColumnRule[] | null;
  columnOptions: TableColumnType[];
};
const CustomGridRow = ({
  children,
  conditionalFormatting,
  columnOptions,
  style,
  row,
  rowId,
  ...rest
}: CustomGridRowProps) => {
  // Here we have some conditions to add styles in all row,
  // it applies only when column value is ALL_COLUMNS
  const rowStyles = useMemo(() => {
    const rules = conditionalFormatting?.filter((rule) => rule.ruleConfig?.applyTo === ALL_COLUMNS);
    const conditionalStyles = getRuleStyles(rules, row as RecordItem, columnOptions || []);
    const isGroupingRow = rowId.toString().includes(GRID_SELECT_COL_DEF_INITIAL);
    if (conditionalStyles && !isGroupingRow) return conditionalStyles;
    return;
  }, [conditionalFormatting, columnOptions, row, rowId]);
  return (
    <GridRow {...rest} row={row} rowId={rowId} style={{ ...style, ...rowStyles }}>
      {children}
    </GridRow>
  );
};

export default CustomGridRow;
