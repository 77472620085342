import clsx from "clsx";

const GridCellWrapper = ({ children, className, ...rest }: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div className={clsx(className, "flex h-full w-full items-center justify-center")} {...rest}>
      {children}
    </div>
  );
};

export default GridCellWrapper;
