import { useEffect, useState } from "react";

import useTableData from "hooks/useTableData";
import useMultiTableData, { MultiPageColumns } from "hooks/useMultiTableData";

import { PaginationState, SortItem, TableColumnType, TableFilterType } from "types/baTypes";
import { QueryHookOptions, RecordItem } from "types/common";
import { ExtendedSchema } from "utils/schema";
import { ALTERNATE_SCHEMAS, COMBINED_AGGREGATE_KEY } from "utils/constants";
import { getAggregateFunctionData } from "utils/dataUtils";

const useAggregateData = ({
  aggregateColumns,
  tableName,
  finalSorting,
  tableFilterOptions,
  finalFilters,
  currentSlug,
  altSchema,
  inAddMany,
  source,
  disableFetch,
  finalBaseSchema,
  hookOptions,
  globalSort,
  pagination
}: {
  aggregateColumns: TableColumnType[];
  tableName: string;
  finalSorting: SortItem[];
  tableFilterOptions: { filters: TableFilterType[] };
  finalFilters?: TableFilterType[];
  currentSlug: string;
  altSchema?: ALTERNATE_SCHEMAS;
  inAddMany?: boolean;
  source?: string;
  disableFetch?: boolean;
  finalBaseSchema?: ExtendedSchema;
  hookOptions?: QueryHookOptions;
  globalSort?: SortItem[];
  pagination?: PaginationState;
}) => {
  const [aggregateFunctionColumns, setAggregateFunctionColumns] = useState<TableColumnType[]>(aggregateColumns);
  const [combinedAggregateColumns, setCombinedAggregateColumns] = useState<TableColumnType[]>([]);
  const [finalAggregateData, setFinalAggregateData] = useState<RecordItem>();

  const { data: aggrTableData, isLoading: aggrLoading } = useTableData({
    tableName,
    columns: aggregateFunctionColumns || [],
    isAggregateFetch: true,
    sorting: finalSorting,
    tableFiltersOption: tableFilterOptions,
    filters: finalFilters,
    pagination,
    globalSort,
    isViewFilterSort: currentSlug === "task-library",
    altSchema,
    inAddMany,
    source: "DataGridView",
    hookOptions: {
      enabled: !!aggregateFunctionColumns?.length && !disableFetch && !!Object.keys(finalBaseSchema || {}).length,
      ...hookOptions
    }
  });

  const { setMultiTablesData, results } = useMultiTableData();

  useEffect(() => {
    if (!aggregateColumns?.length) {
      return;
    }
    // Add all non-combined aggregate functions to the aggregateColumns
    setAggregateFunctionColumns(
      aggregateColumns?.filter(
        (col) => !!col.cellConfig?.isAggregate && !col.cellConfig?.aggregateFunction?.isCombinedAggregate
      )
    );
    // Add all combined aggregate functions to the combinedAggregateColumns
    setCombinedAggregateColumns(
      aggregateColumns?.filter(
        (col) => !!col.cellConfig?.isAggregate && !!col.cellConfig?.aggregateFunction?.isCombinedAggregate
      )
    );
  }, [aggregateColumns]);

  useEffect(() => {
    if (!combinedAggregateColumns?.length) {
      return;
    }
    const finalMultiTableQueries: MultiPageColumns[] = [];
    combinedAggregateColumns?.forEach((column) => {
      if (column?.cellConfig?.aggregateFunction?.function) {
        finalMultiTableQueries.push({
          tableName,
          columns: [column],
          filters: tableFilterOptions?.filters,
          resultId: column.id,
          isAggregateFetch: true
        });
      }
    });
    if (finalMultiTableQueries?.length) {
      setMultiTablesData(finalMultiTableQueries);
    }
  }, [combinedAggregateColumns, tableName, tableFilterOptions?.filters, setMultiTablesData]);

  useEffect(() => {
    if (
      (!aggregateFunctionColumns && !combinedAggregateColumns) ||
      (!!aggregateFunctionColumns?.length && !aggrTableData) ||
      (combinedAggregateColumns?.length && !results)
    ) {
      return;
    }
    const updatedAggrData: RecordItem = {};
    if (aggregateFunctionColumns?.length && aggrTableData) {
      aggrTableData.forEach((row) => {
        const rowId = row.id;
        const aggrDataByColId: RecordItem = {};
        aggregateFunctionColumns?.forEach((col) => {
          const aggregateValue = getAggregateFunctionData({
            column: col,
            recordData: row
          });
          aggrDataByColId[col.id] = aggregateValue;
        });
        updatedAggrData[rowId] = aggrDataByColId;
      });
    }
    if (combinedAggregateColumns?.length && results) {
      updatedAggrData[COMBINED_AGGREGATE_KEY] = {};
      combinedAggregateColumns?.forEach((col) => {
        const aggregateValue = getAggregateFunctionData({
          column: col,
          recordData: results?.[col.id]
        });
        updatedAggrData[COMBINED_AGGREGATE_KEY][col.id] = aggregateValue;
      });
    }
    setFinalAggregateData(updatedAggrData);
  }, [aggrTableData, results, aggregateColumns, combinedAggregateColumns, aggregateFunctionColumns]);

  return {
    data: finalAggregateData,
    loading: aggrLoading
  };
};

export default useAggregateData;
