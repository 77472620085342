"use client";

import { arrayMove } from "@dnd-kit/sortable";
import Popper from "@mui/material/Popper";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_REORDER_COL_DEF,
  GRID_ROOT_GROUP_ID,
  GridAggregationModel,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
  GridColumnHeaderParams,
  GridColumnHeaders,
  GridColumnOrderChangeParams,
  GridEditModes,
  GridEventListener,
  GridGroupNode,
  GridGroupingColDefOverrideParams,
  GridGroupingValueGetter,
  GridKeyValue,
  GridPaginationModel,
  GridPinnedColumnFields,
  GridRenderCellParams,
  GridRowGroupingModel,
  GridRowId,
  GridRowModel,
  GridRowModesModel,
  GridRowSelectionModel,
  GridSortDirection,
  GridSortModel,
  GridValidRowModel,
  GridValueFormatter,
  GridValueGetter,
  MuiBaseEvent,
  MuiEvent,
  useGridApiRef,
  useKeepGroupedColumnsHidden
} from "@mui/x-data-grid-premium";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import sortBy from "lodash/sortBy";
import {
  CopyCheck as ChildIcon,
  ExpandIcon,
  ExternalLinkIcon,
  InfoIcon,
  ArrowRightLeft as ParentIcon,
  Plus as PlusIcon
} from "lucide-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { v4 } from "uuid";

import useActionByName from "hooks/useActionByName";
import useAggregateData from "hooks/useAggregateData";
import useCurrentUser from "hooks/useCurrentUser";
import useDefaultTablePages from "hooks/useDefaultTablePages";
import useErrorLogger from "hooks/useErrorLogger";
import useFormFieldsSubmission from "hooks/useFormFieldsSubmission";
import useHasScrollbar from "hooks/useHasScrollbar";
import useInLayout from "hooks/useInLayout";
import useIsParentPage from "hooks/useIsParentPage";
import useKeypressNavigationView from "hooks/useKeypressNavigationView";
import useNotificationsState from "hooks/useNotificationsState";
import useOpenRecordInNestedView from "hooks/useOpenRecordInNestedView";
import useRecordEdit from "hooks/useRecordEdit";
import useRecordTypes from "hooks/useRecordTypes";
import useSchemaState from "hooks/useSchemaState";
import useSearchQueryParams from "hooks/useSearchQueryParams";
import useSortUpdate from "hooks/useSortUpdate";
import useTableActionsState from "hooks/useTableActionsState";
import useTableData from "hooks/useTableData";
import useTableDataById from "hooks/useTableDataById";
import useTransformFormData from "hooks/useTransformFormData";
import useViewFilter from "hooks/useViewFilter";
import useWriteRollupUpdate from "hooks/useWriteRollupUpdate";

import ActionsHandler from "components/ActionsHandler";
import { COLUMN_CONDITIONAL_VISIBILITY } from "components/Admin/utils";
import { getValidFilterTypes } from "components/Bar/Filters/utils";
import Button from "components/Button";
import CellGroup from "components/CellGroup";
import AdminIcon from "components/CellGroup/AdminIcon";
import CellEmpty from "components/CellGroup/Cells/CellEmpty";
import CellText from "components/CellGroup/Cells/CellText";
import FormulaIcon from "components/CellGroup/FormulaIcon";
import ErrorDisplay from "components/CustomError/ErrorDisplay";
import DataLoader from "components/DataLoader";
import EmptyState from "components/EmptyState";
import ErrorView from "components/ErrorView";
import IconButton from "components/IconButton";
import { NOTIFICATION_ACTION, NOTIFICATION_TYPE } from "components/Notifications/utils";
import TableCreateButton from "components/TableCreateButton";
import Tooltip from "components/Tooltip";

import AddColumnButton from "context/PageConfigContext/AddColumnButton";
import LexoRank from "lib/LexoRank";
import {
  getAdditionValueFieldsFromColumnRules,
  getColumnOptionsLookupPropAndColumnName,
  getColumnValueFromRowData,
  getDefaultTablePageForColumn,
  getTextFromColumn,
  getTextFromColumnId,
  getTextFromColumnWithFieldSelected
} from "lib/utils";
import {
  ColumnColorType,
  ColumnConditionalRule,
  ColumnRule,
  ColumnWebhookAction,
  Page,
  SortItem,
  TableColumnType,
  TablePageViewTab,
  TableViewConfig,
  TableViewType,
  ViewAction
} from "types/baTypes";
import { QueryHookOptions, RecordItem } from "types/common";
import { getActionVisibilityRule, getFinalVisibilityFromVisibilityRule } from "utils/actions";
import {
  appendRecordTypeLookupToCol,
  getFinalVisibilityFromColumnRule,
  getTimeStamp,
  getVisibilityByColumnRule
} from "utils/columnUtils";
import {
  AGGREGATE_COLUMN_TYPES,
  ALTERNATE_SCHEMAS,
  ActionType,
  AdditionalFormActions,
  ButtonAppearance,
  CALLBACK_UI_ACTIONS,
  CALLBACK_UI_ACTION_TYPES,
  COMBINED_AGGREGATE_KEY,
  CellType,
  CrudActions,
  DB_VIEWS,
  DEFAULT_ROW_DENSITY,
  DensityType,
  EMPTY_GROUP_FIELD,
  ERROR_TYPES,
  FILTER_BY_COLUMN_TYPES,
  FILTER_OPERATOR,
  FORM_DEFAULT_VALUE_TYPE,
  GENERIC_CELL_LAYOUTS,
  GroupByInfo,
  INITIAL_GRID_PAGINATION_MODEL,
  INITIAL_PAGINATION_STATE,
  INITIAL_RECORD_PAGINATION_STATE,
  InlineEditingCellTypes,
  LookupTypes,
  ROW_DENSITY_OPTIONS,
  SIDEBAR_TYPE,
  STATIC_SIDEBAR_IDS,
  SelectCellTypes,
  UI_ACTIONS,
  USER_TYPE,
  ViewOption
} from "utils/constants";
import {
  generateFinalDataColumns,
  getColumnHeaderTypeForRecordKey,
  hasAFileTable,
  isColumnFileTag,
  isColumnNestedFileTag,
  isColumnTagsJoinTable
} from "utils/dataUtils";
import toast from "utils/toast";
import CustomColumnMenu from "./CustomColumnMenu";
import CustomGridRow from "./CustomGridRow";
import CellGroupEdit from "./GridCellGroupEdit";
import GridCellWrapper from "./GridCellWrapper";
import { GroupingCollapseIcon, GroupingExpandIcon } from "./GridUtilComponents";
import GroupCell from "./GroupCell";
import StyledDataGridPro from "./StyledGridPro";
import {
  GRID_EXPAND_COL_DEF,
  GRID_FOOTER_COL_DEF,
  GRID_GROUP_BY_COL_DEF,
  GRID_GROUP_PARENT_ROOT,
  GRID_SELECT_COL_DEF_INITIAL,
  NEW_INLINE_ROW_ID,
  NEW_LOADER_INLINE_ROW_ID,
  NEW_ROW_ADD_ROW,
  currencyFormatter,
  getColumnFieldIdentifier,
  getGroupByColumnFieldIdentifier,
  getGroupByColumnIdFromField,
  getRemovedRows,
  getRuleStyles,
  getSelectedRows,
  getSizeBasedInRowDensity,
  getStylesForCol,
  getType,
  isKeyboardEvent,
  sortingOrder,
  transformDataForGridView
} from "./utils";

type DataGridProps = {
  columnOptions: TableColumnType[] | null;
  tableName: string;
  tableFiltersOption?: TableViewType;
  tableTitle: string;
  currentSlug: string;
  multiSelectEnabled: boolean;
  gridViewWidth?: Record<string, number>;
  onRowClick?: (record: RecordItem, fileColId?: string) => void;
  setGridApiRef?: (ref: { current: any }) => void;
  inDetailView?: boolean;
  actions?: ViewAction[];
  globalSort?: SortItem[];
  inAddMany?: boolean;
  config?: TableViewConfig;
  inSidebar?: boolean;
  isFormEnabled?: boolean;
  tabConfig?: TablePageViewTab;
  showAddLabel?: boolean;
  conditionalFormatting?: ColumnRule[] | null;
  columnColors?: ColumnColorType[] | null;
  parentRecordId?: string;
  parentRecordSlug?: string;
  tabSlug?: string;
  inlineCreationEnabled?: boolean;
  additionDataFields?: RecordItem;
  onActionsClick?: (action: ViewAction, row: RecordItem) => void;
  formConfig?: TableViewConfig;
  isAdmin?: boolean;
  dataHookOptions?: QueryHookOptions;
  isFormCollapsible?: boolean;
  isParentRecordLocked?: boolean;
  pageId?: string;
  multiFormEnabled?: boolean;
  multiFormViewIds?: string[];
  containerClassName?: string;
  pageData?: Page;
  altSchema?: ALTERNATE_SCHEMAS;
  disableFetch?: boolean;
  pageConfigLoading?: boolean;
  inTabView?: boolean;
};
const MemoizedRow = React.memo(CustomGridRow);
const MemoizedColumnHeaders = React.memo(GridColumnHeaders);

const DataGridView = ({
  columnOptions,
  tableName,
  tableFiltersOption,
  tableTitle,
  multiSelectEnabled,
  onRowClick,
  currentSlug,
  gridViewWidth,
  actions = [],
  globalSort = [],
  inAddMany = false,
  config,
  inSidebar = false,
  isFormEnabled = false,
  tabConfig,
  showAddLabel = false,
  isFormCollapsible = false,
  conditionalFormatting,
  columnColors,
  parentRecordId,
  parentRecordSlug,
  tabSlug,
  inlineCreationEnabled = false,
  additionDataFields,
  onActionsClick,
  isAdmin,
  dataHookOptions,
  isParentRecordLocked,
  pageId,
  multiFormEnabled = false,
  multiFormViewIds,
  containerClassName = "",
  pageData,
  altSchema,
  inDetailView,
  disableFetch = false,
  pageConfigLoading = false,
  inTabView
}: DataGridProps) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const apiRef = useGridApiRef();
  const setEditCellRef = useRef<string | null>(null); // Used for new row in join table
  const scrollToNewInlineRowRef = useRef<string | null>(null);
  const setInlineEditCellRef = useRef<string | null>(null); // Used when new row is added in non-join table
  const rowsSortOrderRef = useRef<RecordItem>({}); // Stores the client side sort order for all rows
  const lastGroupCollapsedStateUpdatedRef = useRef<boolean | undefined>(); // Tracks the last group collapsed state that the grid was updated with
  const formWebhookNotificationIdRef = useRef<string | undefined>();
  const showLoaderRowRef = useRef<boolean>(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const { setParams, pushRoute } = useSearchQueryParams();
  const currentUser = useCurrentUser();
  // ##HARDCODED
  const { data: webhookActionData } = useActionByName(UI_ACTIONS.MAKE_WEBHOOK);
  const { data: defaultTablePages } = useDefaultTablePages();
  const {
    tableRowsStateBySlug,
    setTotalCount,
    setDataRowsIds,
    setDataFirstRowsIds,
    setGridApiRef,
    sortingByTableSlug,
    muiFilterModel,
    setMuiFilterModel,
    paginationByTableSlug,
    filtersByTableSlug,
    updatePaginationByTableSlug,
    rowDensityByTableSlug,
    updateRowDensityByTableSlug,
    updateSidebarState,
    bulkActionsStateByTableSlug,
    updateBulkActionsStateByTableSlug,
    columnVisibilityModelByTableSlug,
    updateColumnsVisibilityModelByTableSlug,
    tableSearchStateBySlug,
    groupByInfoByTableSlug,
    updateGroupByInfoByTableSlug,
    updateFiltersByTableSlug,
    updateOpenFiltersByTableSlug,
    currentTablePage,
    gridApiRef,
    currentProjectId,
    formWebhookAddedRecordId,
    updateFormWebhookAddedRecordId,
    sectionFiltersByTableSlug,
    gridPaginationByTableSlug,
    updateGridPaginationByTableSlug,
    superAdminOrgIdFilterDisabledByTableSlug
  } = useTableActionsState();
  const { openRecordInNestedView } = useOpenRecordInNestedView();
  const hasScrollbar = useHasScrollbar();
  const { isParentPage } = useIsParentPage(pageId || "");
  const { addPendingNotification } = useNotificationsState();
  const { logError } = useErrorLogger();

  const { schemaInstance, schema } = useSchemaState();
  const { processColumnsAndUpdateTableData } = useWriteRollupUpdate();
  const queryClient = useQueryClient();
  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumnFields>({
    left: [],
    right: []
  });
  const initColPinned = useRef(false);
  const initAggregationModel = useRef(false);
  const initColGroupByApplied = useRef(false);
  const initColGroupCol = useRef<string[]>([]);
  const initVisibilityColumnsApplied = useRef(false);
  const [parentHeight, setParentHeight] = useState(0);
  const parentRef = useRef<HTMLDivElement>(null);
  const [editedRecordId, setEditedRecordId] = useState<string | undefined>();
  const [groupExpandedState, setGroupExpandedState] = useState<{ [key: string]: boolean }>({}); // Used to keep track of expanded rows

  // Final table data state or the rows prop passed to the grid should only be updated
  // when new rows are added. This is the only time the grid needs to re-render.
  // Use apiRef.current.updateRows to update the grid rows in all other cases.
  const [finalTableData, setFinalTableData] = useState<RecordItem[]>([]);
  const [finalPaginatedTableData, setFinalPaginatedTableData] = useState<RecordItem[]>([]);
  const [pagesAdded, setPagesAdded] = useState<number[]>([]);
  const [highlightedRowId, setHighlightedRowId] = useState<string | null>(null);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [aggregationModel, setAggregationModel] = useState<GridAggregationModel>({});
  const [rowGroupingModel, setRowGroupingModel] = useState<GridRowGroupingModel>([]);
  const [prevRowGroupingModel, setPrevRowGroupingModel] = useState<GridRowGroupingModel>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorRowId, setAnchorRowId] = useState<string | undefined>();
  const [inlineRows, setInlineRows] = useState<RecordItem[] | undefined>();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(INITIAL_GRID_PAGINATION_MODEL);
  const [sortedRows, setSortedRows] = useState<RecordItem[]>([]);
  const [cellIsEditing, setCellIsEditing] = useState(false);
  const [newRowsToAddToTable, setNewRowsToAddToTable] = useState<RecordItem[]>();
  const [finalAggregateData, setFinalAggregateData] = useState<RecordItem>(); // Aggregate column data by row id
  const [isMounted, setIsMounted] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const finalBaseSchema = pageData?.page_config?.alternateSchema
    ? schemaInstance?.alternateSchema?.[pageData?.page_config?.alternateSchema]
    : schemaInstance?.extendedSchema;

  const pageFormConfig = pageData?.views?.find((view) => view.viewType === ViewOption.FORM);
  const pageDetailViewTabs = pageData?.views?.find((view) => view.viewType === ViewOption.DETAIL_MAIN)?.tabs;

  const isFileTable = hasAFileTable(tableName, finalBaseSchema);

  const FINAL_INIT_PAGINATION =
    inDetailView && !inAddMany && !isFileTable ? INITIAL_RECORD_PAGINATION_STATE : INITIAL_PAGINATION_STATE;

  const finalSlug = inAddMany ? `${currentSlug}_add_many` : currentSlug;

  const { isTable } = useInLayout();

  const searchResultIds = tableSearchStateBySlug?.[currentSlug]?.resultIds;
  const enableSortDragAndDrop = !!config?.additionalConfig?.sortDragAndDrop?.enabled;
  const isSearchResultShown = !!searchResultIds?.length;
  const isSearchActive = (tableSearchStateBySlug?.[currentSlug]?.searchValue?.length || 0) > 2;
  const searchTotalCount = tableSearchStateBySlug?.[currentSlug]?.totalCount;

  const parentOffsetHeight = parentRef?.current?.offsetHeight;
  const { data: recordTypesData } = useRecordTypes();
  const [pinnedRowsIds, setPinnedRowsIds] = useState<{
    top: GridRowId[];
  }>({
    top: []
  });

  const viewName =
    (tableName && currentSlug) || !schema?.[tableName] ? `view_${tableName}_${currentSlug.split("-").join("_")}` : "";
  const pageViewFilter = !!schema?.[viewName] ? viewName : undefined;
  const currentPaginationPage = paginationByTableSlug?.[currentSlug]?.currentPage;
  const paginationSize = paginationByTableSlug?.[currentSlug]?.pageSize;

  const {
    resultIds,
    totalCount: filterCount,
    currentPage: filteredPage,
    isLoading: isFilterPageLoading
  } = useViewFilter(currentSlug, pageViewFilter);

  const isAlgoliaEmptySearchResults =
    !!tableSearchStateBySlug?.[currentSlug] && isSearchActive && !searchResultIds?.length;

  const isJoinTable = !!finalBaseSchema?.[tableName]?.compositePk?.length;

  // on page change reset the grid state
  useEffect(() => {
    if (!pageConfigLoading) return;
    initColPinned.current = false;
    initAggregationModel.current = false;
    initColGroupByApplied.current = false;
    initColGroupCol.current = [];
    initVisibilityColumnsApplied.current = false;
    setFinalTableData([]);
    setFinalPaginatedTableData([]);
  }, [currentSlug, pageConfigLoading]);

  const handleRowClick = useCallback(
    (row: RecordItem, fileColId?: string) => {
      if (!isTable) {
        onRowClick?.(row, fileColId);
        return;
      }
      const defaultTabId = pageData?.views?.find((view) => view.viewType === ViewOption.DETAIL_MAIN)?.defaultPageTab;
      const defaultTab = pageDetailViewTabs?.find((tab) => tab.id === defaultTabId);
      const finalId = row?.public_id && currentUser?.type !== USER_TYPE.STAFF ? row.public_id : row.id;
      const isProjectsPage = pageData?.table_name === "projects";
      const finalPath = isProjectsPage ? "projects" : pageData?.path?.replace("/", "");

      if (pageData?.page_config?.skip_record_sidebar_view || (pageData && isMobile && !config?.disableExpand)) {
        pushRoute({ newPath: `/r/${finalPath}/${finalId}${defaultTab?.page?.path ? defaultTab.page.path : ""}` });
        return;
      }

      if (pageData) {
        if (!config?.disableExpand) {
          // Add Sidebar search param to open sidebar
          if ((tableName === "files" && inDetailView && fileColId) || (isTable && inSidebar)) {
            setParams({
              cellSide: {
                pageId: pageData.id,
                recordId: row.id,
                fileColId: fileColId
              }
            });
          } else {
            setParams({
              sidebar: {
                pageId: pageData.id,
                recordId: row.id,
                fileColId
              }
            });
          }
        }
      }
      if (gridApiRef.current) {
        gridApiRef.current.selectRow(row.id);
      }
    },
    [
      pageData,
      isMobile,
      gridApiRef,
      currentUser?.type,
      config?.disableExpand,
      isTable,
      onRowClick,
      setParams,
      pushRoute,
      inSidebar,
      inDetailView,
      tableName,
      pageDetailViewTabs
    ]
  );

  const handleColumnWebhookActionClick = useCallback(
    (columnInfo: TableColumnType, webhookAction: ColumnWebhookAction) => {
      const webhookUrl = webhookAction?.url;
      const timestamp = getTimeStamp();
      if (!webhookUrl || !webhookActionData) return;

      fetch(webhookUrl, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          action: webhookActionData.name,
          actionLabel: webhookAction.label,
          actionId: webhookActionData?.id,
          tableName,
          userId: currentUser?.user_id,
          timestamp,
          column: {
            id: columnInfo.id,
            pageColumnId: columnInfo.pageColumnId,
            viewColumnId: columnInfo.viewColumnId,
            name: columnInfo.name,
            lookupPath: columnInfo.lookupPath,
            header: columnInfo.header
          },
          page: {
            id: pageData?.id,
            title: pageData?.title,
            table_name: pageData?.table_name,
            path: pageData?.path
          },
          action_props: {
            id: v4(),
            currentTablePage: `/${currentSlug}`,
            projectId: currentProjectId
          }
        })
      });
      toast.success("Webhook action triggered successfully");
    },
    [webhookActionData, currentUser?.user_id, currentProjectId, tableName, currentSlug, pageData]
  );

  const handleGroupByWebhookActionClick = useCallback(
    ({
      rows,
      groupByColumn,
      groupingKey
    }: {
      rows: GridRowId[];
      groupByColumn?: TableColumnType;
      groupingKey?: GridKeyValue | null;
    }) => {
      const webhookUrl = config?.additionalConfig?.groupWebhookActionUrl;
      const timestamp = getTimeStamp();
      if (!webhookUrl || !webhookActionData) return;

      fetch(webhookUrl, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          action: webhookActionData.name,
          actionId: webhookActionData?.id,
          recordsIds: rows,
          tableName,
          userId: currentUser?.user_id,
          timestamp,
          groupByColumnId: groupByColumn?.id,
          groupingKey: groupingKey,
          action_props: {
            id: v4(),
            currentTablePage: `/${currentSlug}`,
            projectId: currentProjectId
          }
        })
      });
      toast.success("Webhook action triggered successfully");
    },
    [
      webhookActionData,
      config?.additionalConfig?.groupWebhookActionUrl,
      currentUser?.user_id,
      currentProjectId,
      tableName,
      currentSlug
    ]
  );

  const finalColumnOptions = useMemo(() => {
    return !finalBaseSchema
      ? []
      : (columnOptions
          ?.map((col) => appendRecordTypeLookupToCol(col, recordTypesData || [], tableName, finalBaseSchema))
          ?.filter(Boolean) as TableColumnType[]) || [];
  }, [columnOptions, recordTypesData, tableName, finalBaseSchema]);

  const { handleUpdateSorting } = useSortUpdate({ tableSlug: currentSlug, columns: finalColumnOptions });
  const pinnedColsByDefault = useMemo(() => {
    return {
      left:
        finalColumnOptions
          ?.filter((col) => col.views?.[ViewOption.GRID]?.pinDirection === "left")
          ?.map((col) => col.id) || [],
      right:
        finalColumnOptions
          ?.filter((col) => col.views?.[ViewOption.GRID]?.pinDirection === "right")
          ?.map((col) => col.id) || []
    };
  }, [finalColumnOptions]);

  const { formColumns, joinTableRelationInfo, formFieldsArray } = useTransformFormData({
    tablePath: currentSlug,
    parentRecordId,
    parentRecordSlug,
    tabSlug,
    action: tabConfig?.hasAdd ? undefined : CrudActions.CREATE,
    columns: finalColumnOptions,
    pageData
  });

  const finalFormColumns = useMemo(() => {
    if (!formColumns?.length) return formColumns;
    const colExistInForm = formColumns.find(
      (col) => col.id === config?.additionalConfig?.sortDragAndDrop?.columnToUpdate
    );
    const finalCols = [...formColumns];
    if ((enableSortDragAndDrop || inlineCreationEnabled) && !colExistInForm) {
      const colToUpdate = finalColumnOptions?.find(
        (col) => col.id === config?.additionalConfig?.sortDragAndDrop?.columnToUpdate
      );
      if (colToUpdate) {
        finalCols.push(colToUpdate);
      }
    }

    // add grouped by columns to formColumns
    if (inlineCreationEnabled && rowGroupingModel.length) {
      rowGroupingModel.forEach((model) => {
        if (!finalCols.some((col) => col.id === model)) {
          const groupCol = finalColumnOptions?.find((col) => col.id === model);
          if (groupCol) {
            finalCols.push(groupCol);
          }
        }
      });
    }

    return finalCols;
  }, [enableSortDragAndDrop, formColumns, finalColumnOptions, config, inlineCreationEnabled, rowGroupingModel]);

  // This the editable column part of the composite key
  const compositeKeysColumn = useMemo(() => {
    if (!finalBaseSchema?.[tableName]?.compositePk?.length || !finalColumnOptions?.length) return [];
    const compositeKeys = finalBaseSchema[tableName].compositePk;
    // This currently only works with projects, as one composite key is project_id, filled by currentProjectId
    // TODO: extend to match any table using currentRecordId
    if (compositeKeys?.find((key) => key.table === "projects")) {
      const otherKey = compositeKeys.find((key) => key.table !== "projects" && key.attributeId !== "id");
      if (otherKey) {
        // Find the matching column
        const col = finalColumnOptions.find((col) => {
          if (
            col.isLookup &&
            !isEmpty(col.columnOptionsLookUp) &&
            col.isEditable &&
            col.lookupPath?.[0]?.lookupForeignKey === otherKey.attributeId
          ) {
            return true;
          }
          return false;
        });
        return col;
      }
    }
    return undefined;
  }, [finalBaseSchema, tableName, finalColumnOptions]);

  const { onFormSubmitWithRecord, onFormSubmitWithOptions } = useFormFieldsSubmission({
    tableName,
    formColumns: finalFormColumns || [],
    slug: currentSlug,
    joinTableRelationInfo,
    alternateSchema: pageData?.page_config?.alternateSchema,
    source: "DataGridView"
  });

  const bulkActionsState = bulkActionsStateByTableSlug?.[currentSlug];
  const [displayColumnSortedIds, setDisplayColumnSortedIds] = useState<string[]>([]);
  const [draggingColumn, setDraggingColumn] = useState<
    { isDragEnd?: boolean; params: GridColumnOrderChangeParams } | undefined
  >();

  const finalDisplayColumns = useMemo(() => {
    return sortBy(
      (finalColumnOptions || []).filter(
        (col) =>
          ((enableSortDragAndDrop || inlineCreationEnabled) &&
            col.id === config?.additionalConfig?.sortDragAndDrop?.columnToUpdate) ||
          (!col.views?.[ViewOption.GRID]?.isHidden &&
            col.views?.[ViewOption.GRID]?.id &&
            !!col.isActive &&
            !(col.isRollup && !col.name) &&
            (col.isAdminColumn ? currentUser?.is_admin : true))
      ),
      `sortOrder.${ViewOption.GRID}`
    );
  }, [
    finalColumnOptions,
    enableSortDragAndDrop,
    config?.additionalConfig?.sortDragAndDrop?.columnToUpdate,
    inlineCreationEnabled,
    currentUser?.is_admin
  ]);

  const aggregateFunctionColumns = useMemo(() => {
    return finalColumnOptions.filter((col) => !!col.cellConfig?.isAggregate);
  }, [finalColumnOptions]);

  const defaultViewSection = useMemo(() => {
    const defaultVisSection = config?.defaultColumnSection;
    const updatedColumnVisibilityModel: { [key: string]: boolean } = {};
    if (defaultVisSection && finalDisplayColumns?.length) {
      defaultVisSection.columns = [];
      finalDisplayColumns.forEach((col) => {
        const pageColView = col.views?.[ViewOption.GRID];
        updatedColumnVisibilityModel[col.id] = false;
        if (pageColView?.columnSections?.length) {
          const currentSection = pageColView.columnSections.find(
            (colSection) => colSection.id === defaultVisSection.id
          );
          if (currentSection) {
            (defaultVisSection.columns as TableColumnType[]).push(col);
            updatedColumnVisibilityModel[col.id] = true;
          }
        }
      });
    }
    return updatedColumnVisibilityModel;
  }, [config, finalDisplayColumns]);

  const firstCol = getColumnFieldIdentifier(finalDisplayColumns?.[0]);

  const columnVisibilityModel = useMemo(() => {
    const finalVisibilityModel = !isEmpty(columnVisibilityModelByTableSlug?.[currentSlug]?.[ViewOption.GRID])
      ? columnVisibilityModelByTableSlug?.[currentSlug]?.[ViewOption.GRID]
      : defaultViewSection;
    if (bulkActionsState?.selectedRows?.length) {
      return {
        ...(finalVisibilityModel || {}),
        actions: false
      };
    }

    return finalVisibilityModel;
  }, [bulkActionsState?.selectedRows?.length, columnVisibilityModelByTableSlug, currentSlug, defaultViewSection]);

  const finalDisplayColumnsById = useMemo(() => {
    return sortBy(
      finalDisplayColumns.filter((col) => col.views?.[ViewOption.GRID]?.id && !col.views?.[ViewOption.GRID].isHidden),
      (col) => displayColumnSortedIds.indexOf(col.id)
    ).reduce((acc, col, currentIdx) => {
      const isEditable = col.isEditable && InlineEditingCellTypes.includes(col.type) && col.enableInlineEditing;
      acc[col.id] = { ...col, colIdx: currentIdx, isEditable };
      return acc;
    }, {} as RecordItem);
  }, [finalDisplayColumns, displayColumnSortedIds]);

  useEffect(() => {
    if (
      finalDisplayColumns.length !== displayColumnSortedIds.length ||
      !finalDisplayColumns.every((col) => displayColumnSortedIds.includes(col.id))
    ) {
      setDisplayColumnSortedIds(finalDisplayColumns.map((col) => col.id));
    }
  }, [finalDisplayColumns, displayColumnSortedIds]);

  useEffect(() => {
    if (!draggingColumn || !draggingColumn.isDragEnd || !draggingColumn.params) return;

    setDraggingColumn(undefined);
    setDisplayColumnSortedIds((prev) => {
      const oldIndex = prev.findIndex((colId) => colId === draggingColumn.params.column?.field);
      return arrayMove(prev, oldIndex, draggingColumn.params.targetIndex - 2);
    });
  }, [draggingColumn]);

  const finalDataColumns = useMemo(() => {
    if (!finalColumnOptions?.length) return [];
    return generateFinalDataColumns({
      columns: finalColumnOptions,
      view: ViewOption.GRID,
      filters: filtersByTableSlug?.[currentSlug],
      config,
      isAdmin,
      recordTypesData,
      tableName,
      extendedSchema: finalBaseSchema
    });
  }, [
    finalColumnOptions,
    filtersByTableSlug,
    currentSlug,
    config,
    isAdmin,
    recordTypesData,
    tableName,
    finalBaseSchema
  ]);

  const handleEmailClick = useCallback(
    (row?: RecordItem) => {
      if (!row?.id) return;
      let finalRecord = row;
      const emailPeopleId: { id?: string } = {};
      if (!row.email) {
        finalRecord = finalDisplayColumns.reduce((acc: RecordItem, col) => {
          const { lookupCols, finalPropName } = getColumnOptionsLookupPropAndColumnName(col);
          const value = getTextFromColumnId(col.id, finalDisplayColumns, row, finalBaseSchema);

          if (lookupCols === "email" && finalPropName) {
            emailPeopleId.id = row[finalPropName as string]?.id;
          }
          acc[col.isLookup ? lookupCols : col.name] = value;
          return acc;
        }, {});
      }
      if (!finalRecord?.email) return;
      if (emailPeopleId?.id) {
        finalRecord = { ...finalRecord, ...emailPeopleId };
      }
      const prefillValues: { to_company?: RecordItem; to_person?: RecordItem } = {};
      if (tableName === "companies" || row?.company_id) {
        const finalId = row?.company_id || finalRecord?.id;
        prefillValues["to_company"] = { displayValue: { id: finalId }, inputValue: { id: finalId } };
      } else {
        prefillValues["to_person"] = { displayValue: { id: finalRecord.id }, inputValue: { id: finalRecord.id } };
      }
      updateSidebarState(
        {
          isOpen: true,
          action: AdditionalFormActions.EMAIL,
          record: row,
          columns: [],
          tablePath: "emails",
          title: "Email",
          showDetailView: false,
          prefillValues
        },
        STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
      );
    },
    [updateSidebarState, finalDisplayColumns, tableName, finalBaseSchema]
  );

  const finalTableFilterOptions = useMemo(() => {
    const filters = [];
    if (tableFiltersOption?.filters?.length) {
      filters.push(...tableFiltersOption?.filters);
    }

    if (isSearchActive) {
      // Pick result ids for that page
      const finalResultIds =
        paginationSize && currentPaginationPage && currentPaginationPage > 1
          ? searchResultIds?.slice(
              paginationSize * (currentPaginationPage - 1),
              paginationSize * (currentPaginationPage - 1) + paginationSize
            )
          : searchResultIds;
      filters.push({
        filterField: "id",
        filterOperator: "in",
        filterValue: finalResultIds
      });
    }

    if (sectionFiltersByTableSlug?.[currentSlug]?.length) {
      filters.push(...sectionFiltersByTableSlug?.[currentSlug]);
    }

    return { filters };
  }, [
    tableFiltersOption,
    currentSlug,
    isSearchActive,
    searchResultIds,
    sectionFiltersByTableSlug,
    paginationSize,
    currentPaginationPage
  ]);

  const finalSorting = useMemo(() => {
    if (sortingByTableSlug?.[currentSlug]?.length) {
      return sortingByTableSlug?.[currentSlug];
    }
    if ((enableSortDragAndDrop || inlineCreationEnabled) && config?.additionalConfig?.sortDragAndDrop?.columnToUpdate) {
      const colToSort = finalColumnOptions?.find(
        (col) => col.id === config?.additionalConfig?.sortDragAndDrop?.columnToUpdate
      );

      if (colToSort) {
        return [
          {
            id: colToSort?.id,
            name: colToSort?.name,
            desc: false,
            col: colToSort
          }
        ];
      }
    }

    return sortingByTableSlug?.[currentSlug] || [];
  }, [
    enableSortDragAndDrop,
    config?.additionalConfig?.sortDragAndDrop?.columnToUpdate,
    sortingByTableSlug,
    currentSlug,
    finalColumnOptions,
    inlineCreationEnabled
  ]);

  const rowPinningColumn = config?.additionalConfig?.rowPinningColumn
    ? columnOptions?.find((col) => col.id === config.additionalConfig?.rowPinningColumn)
    : undefined;

  const allowRowPinning = !!(rowPinningColumn && !rowGroupingModel.length);

  const finalFilters = useMemo(() => {
    if (
      !pageViewFilter ||
      !(pageViewFilter && (filtersByTableSlug?.[currentSlug]?.length || sortingByTableSlug?.[currentSlug]?.length))
    ) {
      return filtersByTableSlug?.[currentSlug];
    }
    return [
      {
        id: "view_filter",
        filterField: "id",
        filterOperator: FILTER_OPERATOR.IN,
        filterValue: resultIds?.length ? resultIds : ["-1"],
        column: {
          id: "ids",
          name: "id",
          header: "ID",
          isLookup: false,
          lookupPath: undefined,
          type: CellType.TEXT
        }
      }
    ];
  }, [filtersByTableSlug, currentSlug, resultIds, sortingByTableSlug, pageViewFilter]);

  const isPageActiveFiltered = finalFilters?.length && !!pageViewFilter; // When page is using a view as filter and is currently filtered
  const {
    data: tableData,
    isLoading,
    totalCount,
    page,
    refetch,
    isFetched,
    finalQueryKey,
    error
  } = useTableData({
    tableName,
    columns: finalDataColumns,
    sorting: finalSorting,
    tableFiltersOption: finalTableFilterOptions,
    filters: finalFilters,
    pagination: isPageActiveFiltered || isSearchActive ? undefined : paginationByTableSlug?.[currentSlug],
    globalSort: isPageActiveFiltered ? undefined : globalSort, // When sorted by user don't use global sort
    isViewFilterSort: currentSlug === "task-library",
    altSchema: altSchema || pageData?.page_config?.alternateSchema,
    inAddMany,
    source: "DataGridView",
    hookOptions: {
      enabled:
        !!finalDataColumns?.length &&
        !!paginationByTableSlug?.[currentSlug]?.pageSize &&
        !!Object.keys(finalBaseSchema || {}).length &&
        !disableFetch &&
        (!inTabView || (inTabView && !!tableFiltersOption?.filters?.length)) && // If in tab view don't fetch till filters are added
        (!inDetailView || (inDetailView && !!tableFiltersOption?.filters?.length)), // In tab view page data should always be filtered
      ...dataHookOptions,
      refetchOnMount: true
    },
    disableOrgIdFilter: !!superAdminOrgIdFilterDisabledByTableSlug?.[currentSlug]
  });

  const { data: aggrTableData, loading: aggrLoading } = useAggregateData({
    aggregateColumns: aggregateFunctionColumns,
    tableName,
    finalFilters,
    finalSorting,
    tableFilterOptions: finalTableFilterOptions,
    globalSort,
    pagination: isPageActiveFiltered || isSearchActive ? undefined : paginationByTableSlug?.[currentSlug],
    altSchema: altSchema || pageData?.page_config?.alternateSchema,
    currentSlug,
    source: "DataGrid - aggregateData",
    disableFetch,
    inAddMany,
    finalBaseSchema,
    hookOptions: {
      enabled:
        !!aggregateFunctionColumns?.length &&
        !!paginationByTableSlug?.[currentSlug]?.pageSize &&
        !!Object.keys(finalBaseSchema || {}).length &&
        !disableFetch &&
        (!inDetailView || (inDetailView && !!tableFiltersOption?.filters?.length)), // In tab view page data should always be filtered
      ...dataHookOptions,
      refetchOnMount: true
    }
  });

  const finalTotalCount = tableRowsStateBySlug?.[finalSlug]?.totalCount || totalCount;
  // This hook is used to fetch edited records / new records when user scrolls past the first page
  const { data: editedRecordData } = useTableDataById(
    {
      id: editedRecordId || "",
      tableName,
      columns: finalDataColumns,
      altSchema: altSchema || pageData?.page_config?.alternateSchema,
      filters: finalFilters,
      source: "DataGrid - editRecord"
    },
    {
      enabled: !!editedRecordId
    }
  );

  const columnsForRecordEdit = useMemo(() => {
    return finalColumnOptions?.filter((col) => !!col.views?.[ViewOption.GRID]?.id);
  }, [finalColumnOptions]);

  const onRecordEditSuccess = useCallback(
    (updatedItem?: RecordItem) => {
      queryClient.invalidateQueries({ queryKey: finalQueryKey, exact: true, refetchType: "none" });
      const hasFormulaColumn = finalDisplayColumns.some((col) => col.isFormula);
      if (!hasFormulaColumn) return;
      apiRef?.current?.updateRows?.([{ ...updatedItem }]);
    },
    [apiRef, finalDisplayColumns, queryClient, finalQueryKey]
  );

  const { onRecordEdit } = useRecordEdit({
    tableName,
    pageColumns: columnsForRecordEdit,
    view: ViewOption.GRID,
    onSuccess: onRecordEditSuccess
  });

  const finalActions = useMemo(() => {
    if (!actions || !actions?.length || !finalBaseSchema || !tableName) return [];
    // Check to see if table has `is_locked` column otherwise hide Lock Record action
    const tableCols = finalBaseSchema?.[tableName]?.attributeIds;
    const hasIsLocked = tableCols?.includes("is_locked");
    return actions
      .map((action: ViewAction) => {
        if (action.type === ActionType.LOCK && !hasIsLocked) {
          return null;
        }
        if (CALLBACK_UI_ACTIONS.includes(action.name as UI_ACTIONS) || CALLBACK_UI_ACTION_TYPES.includes(action.type)) {
          return {
            ...action,
            onSuccessCallback: () => {
              refetch?.();
            }
          };
        }
        return action;
      })
      .filter(Boolean);
  }, [actions, refetch, finalBaseSchema, tableName]);

  const isFormulaTable = finalDisplayColumns?.some((col) => col.isFormula);

  const onEditDisabled = useCallback(
    (row: RecordItem, col: TableColumnType) => {
      const cellMode = apiRef.current?.getCellMode(row.id, col.id);
      if (
        (cellMode === "edit" && SelectCellTypes.includes(col.type)) ||
        (col.type === CellType.BADGE && col.columnOptionsLookUp)
      ) {
        apiRef.current?.stopCellEditMode({
          id: row.id,
          field: col.id
        });
      }
    },
    [apiRef]
  );

  // Used to reset edit mode for the row, currently used for generic / select cells to show updated value
  const onEditSuccess = useCallback(
    (col: TableColumnType, rowId: string, stopEditMode?: boolean) => {
      if (stopEditMode && apiRef.current && apiRef?.current?.getCellMode?.(rowId, col.id) === "edit") {
        apiRef.current.stopCellEditMode({ id: rowId, field: getColumnFieldIdentifier(col) });
      }
      const finalPage = isPageActiveFiltered ? filteredPage : page;
      if ((finalPage || 0) > 1) {
        // We only fetch the edited record
        setEditedRecordId(rowId);
      } else {
        refetch?.();
      }
    },
    [apiRef, refetch, filteredPage, isPageActiveFiltered, page]
  );

  // Join table inline rows are cleared after each addition as only one can be added at a time
  const onJoinRecordCreatedSuccess = useCallback((newRecord: RecordItem) => {
    setInlineRows(undefined);
    scrollToNewInlineRowRef.current = newRecord?.id;
  }, []);

  // Non join table inline row create adds a record in the DB, this callback sets the edit mode for the row
  const onInlineRecordCreatedSuccess = useCallback(
    (newRecord: RecordItem) => {
      if (newRecord?.id) {
        const rowId = newRecord?.id;
        setInlineEditCellRef.current = rowId;
        setInlineRows(undefined);
        const finalPage = isPageActiveFiltered ? filteredPage : page;
        if ((finalPage || 0) > 1) {
          // We only fetch the edited record
          setEditedRecordId(rowId);
        }
      }
    },
    [filteredPage, isPageActiveFiltered, page]
  );

  const handleRecordEdit = useCallback(
    async (value: any, row: RecordItem, col: TableColumnType, additionalValues?: { textContent?: string }) => {
      const createdInOpts: RecordItem = {};
      if (SelectCellTypes.includes(col.type) || col.isSelect) {
        createdInOpts.createdInOptions = {
          createdIn: "Select - Data Grid",
          createdInPath: window.location.pathname
        };
      }
      if (`${row.id} || ""`.startsWith(NEW_INLINE_ROW_ID)) {
        // This case only happens when the inline row is being added to a join table
        const tableCompositePKs = finalBaseSchema?.[tableName]?.compositePk;
        if (!tableCompositePKs?.length) return;
        const projectIdCol = finalColumnOptions.find((col) => col.name === "project_id");
        const finalInput = {
          [col.id]: value
        };
        if (projectIdCol) {
          finalInput[projectIdCol.id] = currentProjectId;
        }
        // for Long Text cell if plain text column is present update it too
        if (col?.type === CellType.LONG_TEXT) {
          const columnForPlainText = col?.cellConfig?.lexicalEditorProps?.columnForPlainText;
          if (columnForPlainText) {
            finalInput[columnForPlainText] = additionalValues?.textContent;
          }
        }
        if (rowGroupingModel.length) {
          rowGroupingModel.forEach((model) => {
            const groupByCol = finalColumnOptions?.find((col) => col.id === model);
            if (groupByCol) {
              finalInput[groupByCol.id] = Array.isArray(row[groupByCol.id])
                ? row[groupByCol.id].map((item: RecordItem) => ({
                    ...item,
                    id: item?.joinTableId || item?.id
                  }))
                : {
                    ...row[groupByCol.id],
                    id: row[groupByCol.id]?.joinTableId || row[groupByCol.id]?.id
                  };
            }
          });
        }
        setInlineRows((prev) => {
          if (!prev?.length) {
            return [
              {
                [col.id]: value
              }
            ];
          }
          const updatedRows = prev?.map((rowVal) => {
            if (row.id.startsWith(NEW_INLINE_ROW_ID)) {
              return { ...rowVal, [col.id]: value };
            }
            return rowVal;
          });
          return updatedRows;
        });
        const newRecord = await onFormSubmitWithOptions(finalInput, {
          finalFormColumns: finalColumnOptions?.filter(
            (col) => col.isEditable || formColumns?.find((c) => c.id === col.id)
          ),
          onSuccess: onJoinRecordCreatedSuccess
        });
        if (newRecord?.id) {
          rowsSortOrderRef.current = {
            ...rowsSortOrderRef.current,
            [newRecord.id]: row.sortOrder
          };
        }
        return;
      } else {
        const finalRow = row.originalRow || row;
        let finalValue = value;
        const hasFormulaColumn = finalDisplayColumns.some((col) => col.isFormula);
        if (row.originalRow && hasFormulaColumn) {
          const colType = getType(col);
          // Update original row with any data that was changed
          const updatedHeaderMap = getColumnHeaderTypeForRecordKey(finalDisplayColumns);
          // Update row with grid data
          Object.keys(row.originalRow).forEach((key) => {
            const colVal = updatedHeaderMap[key];
            if (colVal?.column?.id) {
              if (colVal.column.id && colVal.column.id !== col.id) {
                finalRow[key] = row[colVal?.column?.id];
              }
            }
          });
          if (colType === "number" && !value) {
            finalValue = 0;
          }
        }

        const additionalEditProps: RecordItem = {};
        additionalEditProps.onRecordUpdateSuccess = () => {
          onEditSuccess(col, row.id, false);
        };
        // This is needed because CellGroup does not call this method directly
        let finalColId = col.id;
        if (col.type === CellType.GENERIC_CELL && col?.cellConfig?.genericConfig?.editColumnId) {
          const editCol = finalDataColumns.find((colVal) => colVal.id === col?.cellConfig?.genericConfig?.editColumnId);
          if (editCol) {
            finalColId = editCol.id;
            additionalEditProps.onRecordUpdateSuccess = () => {
              onEditSuccess(col, row.id, true);
            };
          } else {
            toast.error(
              "The edit column selected for this cell is not enabled in this view. Please update the page config to add the column to this view, it can be hidden."
            );
            return;
          }
        }
        if (
          col.type === CellType.GENERIC_CELL &&
          col.cellConfig?.genericConfig?.layout === GENERIC_CELL_LAYOUTS.COMBINATION_CELL &&
          value?.column?.id
        ) {
          finalColId = value.column.id;
          additionalEditProps.baseGenericColumnId = col.id;
          additionalEditProps.onRecordUpdateSuccess = () => {
            onEditSuccess(col, row.id, true);
          };
        }
        if (SelectCellTypes.includes(col.type) || (col.type === CellType.BADGE && col?.columnOptionsLookUp)) {
          additionalEditProps.onRecordUpdateSuccess = () => {
            onEditSuccess(col, row.id, true);
          };
        }

        onRecordEdit({
          values: finalValue,
          id: finalColId,
          recordDataInRecord: finalRow,
          ...createdInOpts,
          additionalProps: additionalEditProps
        });

        // for Long Text cell if plain text column is present update it too
        if (col?.type === CellType.LONG_TEXT) {
          const columnForPlainText = col?.cellConfig?.lexicalEditorProps?.columnForPlainText;
          if (columnForPlainText) {
            onRecordEdit({
              values: additionalValues?.textContent,
              id: columnForPlainText,
              recordDataInRecord: finalRow,
              ...createdInOpts,
              additionalProps: additionalEditProps
            });
          }
        }
      }
    },
    [
      finalDisplayColumns,
      tableName,
      finalDataColumns,
      finalBaseSchema,
      rowGroupingModel,
      currentProjectId,
      onEditSuccess,
      onFormSubmitWithOptions,
      onRecordEdit,
      onJoinRecordCreatedSuccess,
      finalColumnOptions,
      formColumns
    ]
  );

  const finalTableRowsToRender = useMemo(() => {
    if (!inlineCreationEnabled && !enableSortDragAndDrop && !isSearchResultShown) {
      return undefined;
    }
    const hasFormDBBypass = pageFormConfig?.additionalConfig?.byPassDbInsert;

    let finalRows: RecordItem[] = finalTableData.map((row, index) => {
      if (
        (enableSortDragAndDrop || inlineCreationEnabled) &&
        config?.additionalConfig?.sortDragAndDrop?.columnToUpdate
      ) {
        const currentSortOrder = row[config.additionalConfig?.sortDragAndDrop.columnToUpdate];
        if (currentSortOrder) {
          return {
            ...row,
            sortOrder: currentSortOrder
          };
        }
      }

      return {
        ...row
      };
    });

    if (inlineRows?.length && (isJoinTable || hasFormDBBypass)) {
      inlineRows.forEach((item) => finalRows.push(item));
    }

    if (newRowsToAddToTable?.length) {
      newRowsToAddToTable.forEach((newRow) => {
        if (!finalRows.find((r) => r.id === newRow.id)) {
          const finalSortOrder = config?.additionalConfig?.sortDragAndDrop?.columnToUpdate
            ? newRow?.[config?.additionalConfig?.sortDragAndDrop?.columnToUpdate]
            : rowsSortOrderRef.current?.[newRow.id] || newRow?.originalRow?.created_at;
          finalRows.push({ ...newRow, sortOrder: finalSortOrder });
        }
      });
      if (!showLoaderRowRef?.current) {
        finalRows = finalRows.filter((row) => !`${row.id}`.startsWith(NEW_LOADER_INLINE_ROW_ID));
      }
      if (
        formWebhookNotificationIdRef?.current &&
        newRowsToAddToTable?.find((row) => `${row.id}` === `${formWebhookAddedRecordId}`)
      ) {
        formWebhookNotificationIdRef.current = undefined;
        updateFormWebhookAddedRecordId(undefined);
        showLoaderRowRef.current = false;
      }
    }

    // Add a New Row to the end of each group
    if (rowGroupingModel.length) {
      const groupByCols = rowGroupingModel.map((model) => finalColumnOptions?.find((col) => col.id === model));
      rowGroupingModel.forEach((model, index) => {
        const groupByCol = finalColumnOptions?.find((col) => col.id === model);
        if (groupByCol) {
          // Find unique value for groupByCol in table rows
          const uniqueGroupByValues: RecordItem[] = [];
          finalRows.forEach((row) => {
            const rowGroupByVal = row?.[groupByCol.id];
            let finalRowGroupId = groupByCol?.isLookup
              ? Array.isArray(rowGroupByVal)
                ? rowGroupByVal[0]?.joinTableId || rowGroupByVal[0]?.id
                : rowGroupByVal?.joinTableId || rowGroupByVal?.id
              : rowGroupByVal;
            const newRow = {
              id: `${NEW_ROW_ADD_ROW}_${finalRowGroupId}`,
              [groupByCol.id]: rowGroupByVal
            };
            if (index > 0 && finalRowGroupId) {
              // Check if row has a matching group in previous grouping col
              for (let i = index - 1; i >= 0; i--) {
                const prevCol = groupByCols[i];
                // Find value for this row
                const prevRowGroupVal = prevCol?.id ? row?.[prevCol.id] : undefined;
                if (prevRowGroupVal && prevCol) {
                  const prevRowGroupId = prevCol?.isLookup
                    ? Array.isArray(prevRowGroupVal)
                      ? prevRowGroupVal[0]?.joinTableId || prevRowGroupVal[0]?.id
                      : prevRowGroupVal?.joinTableId || prevRowGroupVal?.id
                    : prevRowGroupVal;
                  if (prevRowGroupId) {
                    finalRowGroupId = `${finalRowGroupId}_${prevRowGroupId}`;
                    newRow[prevCol.id] = prevRowGroupVal;
                    newRow.id = `${NEW_ROW_ADD_ROW}_${finalRowGroupId}`;
                  }
                }
              }
            }
            // We only want one new row for each combination of groupByCol values
            if (finalRowGroupId && !uniqueGroupByValues.includes(finalRowGroupId)) {
              if (!finalRows.find((row) => row.id === newRow.id)) {
                newRow.originalRow = row;
                if (config?.additionalConfig?.showInlineCreationRow) {
                  finalRows.push(newRow);
                }
                uniqueGroupByValues.push(finalRowGroupId);
              }
            }
          });
        }
      });
    } else {
      if (
        inlineCreationEnabled &&
        config?.additionalConfig?.showInlineCreationRow &&
        !finalRows.find((row) => `${row.id}`.startsWith(NEW_ROW_ADD_ROW)) &&
        finalRows.length
      ) {
        finalRows.push({
          id: NEW_ROW_ADD_ROW,
          [finalDisplayColumns?.[0]?.id]: "New Row",
          originalRow: finalRows[finalRows?.length - 1]
        });
      }
    }
    // Adding sort order field to allow insert of row at correct position
    const ranks = LexoRank.getNewRanks([], finalRows.length, 0, 0);
    finalRows = finalRows.map((rowItem, index) => {
      if (!rowsSortOrderRef?.current?.[rowItem.id] && !`${rowItem.id}`.startsWith(NEW_ROW_ADD_ROW)) {
        rowsSortOrderRef.current = {
          ...rowsSortOrderRef.current,
          [rowItem.id]: ranks[index]?.toString()
        };
      }
      return {
        ...rowItem,
        sortOrder: rowsSortOrderRef.current[rowItem.id]
      };
    });
    const sortedRows = sortBy(finalRows, "sortOrder");

    return sortedRows;
  }, [
    inlineCreationEnabled,
    enableSortDragAndDrop,
    finalTableData,
    inlineRows,
    config?.additionalConfig?.sortDragAndDrop?.columnToUpdate,
    isSearchResultShown,
    isJoinTable,
    rowGroupingModel,
    finalColumnOptions,
    finalDisplayColumns,
    newRowsToAddToTable,
    pageFormConfig,
    formWebhookAddedRecordId,
    updateFormWebhookAddedRecordId,
    config?.additionalConfig?.showInlineCreationRow
  ]);

  const finalGridRows = useMemo(() => {
    if (isAlgoliaEmptySearchResults) return [];

    if (
      ((inlineCreationEnabled && !sortingByTableSlug?.[currentSlug]?.length) ||
        enableSortDragAndDrop ||
        isSearchResultShown) &&
      !config?.additionalConfig?.isPaginatedView
    ) {
      return finalTableRowsToRender;
    }

    return config?.additionalConfig?.isPaginatedView ? finalPaginatedTableData : finalTableData;
  }, [
    isAlgoliaEmptySearchResults,
    config,
    finalTableData,
    inlineCreationEnabled,
    sortingByTableSlug,
    currentSlug,
    enableSortDragAndDrop,
    isSearchResultShown,
    finalTableRowsToRender,
    finalPaginatedTableData
  ]);

  const handleInlineRowReset = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // TODO: Move this to a common hook with Form/index.tsx
  const submitRowToFormWebhook = useCallback(
    (formData: RecordItem) => {
      if (!pageFormConfig?.additionalConfig?.formSubmitWebhook?.url) {
        return;
      }
      const dataByColName: RecordItem = {};
      Object.keys(formData).forEach((key) => {
        const col = finalColumnOptions?.find((col) => col.id === key);
        if (col?.isLookup) {
          const { lookupPath } = col;
          const colKey = `${
            lookupPath?.["0"]?.lookupColumnLabel ? lookupPath?.["0"]?.lookupColumnLabel + "." : ""
          }${lookupPath?.["0"]?.lookupTableName}`;
          dataByColName[colKey] = formData[key];
        } else if (col?.name) {
          dataByColName[col.name] = formData[key];
        }
      });

      const dataToWebhook: RecordItem = {
        formData: dataByColName
      };

      if (pageFormConfig?.additionalConfig?.byPassDbInsert) {
        dataToWebhook["additionalData"] = {
          user: currentUser?.id,
          project: currentProjectId,
          path: pageData?.path,
          inlineCreate: true
        };
        // Add custom default values
        if (pageFormConfig?.additionalConfig?.hasDefaultValues) {
          const defaultData: RecordItem = {};

          pageFormConfig?.additionalConfig?.defaultValues?.forEach((defaultValue) => {
            if (defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.CUSTOM) {
              defaultData[defaultValue?.customField || ""] = defaultValue?.value;
            } else if (
              (defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN ||
                defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.LOOKUP_COLUMN) &&
              defaultValue?.columnId
            ) {
              const defaultCol = pageData?.columns?.find((col) => col.id === defaultValue?.columnId);
              if (defaultCol) {
                if (defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.BASIC_COLUMN) {
                  defaultData[defaultCol?.name || ""] = defaultValue?.value;
                } else if (defaultCol.lookupPath?.["0"]?.lookupType === LookupTypes.FOREIGN) {
                  defaultData[defaultCol.lookupPath?.["0"]?.lookupForeignKey || ""] = defaultValue?.value;
                } else if (Object.keys(defaultValue?.value || {})?.length) {
                  Object.keys(defaultValue?.value || {}).forEach((colKey: string) => {
                    defaultData[colKey] = (defaultValue?.value as RecordItem)?.[colKey];
                  });
                }
              }
            }
          });

          dataToWebhook.additionalData = {
            ...dataToWebhook.additionalData,
            ...defaultData
          };
        }
      }

      const notificationId = v4();

      if (pageFormConfig?.additionalConfig?.waitForSuccess) {
        dataToWebhook["action_props"] = {
          id: notificationId,
          pageTable: pageData?.table_name,
          currentTablePage: pageData?.path,
          projectId: currentProjectId,
          action: NOTIFICATION_ACTION.ACTION_SUCCESS
        };
      }

      // Add custom default values
      if (pageFormConfig?.additionalConfig?.hasDefaultValues) {
        const defaultData: RecordItem = {};
        pageFormConfig?.additionalConfig?.defaultValues?.forEach((defaultValue) => {
          if (defaultValue?.type === FORM_DEFAULT_VALUE_TYPE.CUSTOM) {
            defaultData[defaultValue?.customField || ""] = defaultValue?.value;
          }
        });

        dataToWebhook.action_props = {
          ...dataToWebhook.action_props,
          ...defaultData
        };
      }

      fetch(pageFormConfig.additionalConfig.formSubmitWebhook.url, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataToWebhook)
      });

      if (pageFormConfig?.additionalConfig?.waitForSuccess) {
        addPendingNotification({
          id: notificationId,
          recordId: "",
          pageId: pageData?.id || "",
          tableName: pageData?.table_name || "",
          userId: currentUser?.user_id || "",
          action: "",
          action_props: {
            id: notificationId,
            currentTablePage: pageData?.path,
            hasWaitForSuccess: pageFormConfig?.additionalConfig?.waitForSuccess,
            noRefetch: true
          },
          type: NOTIFICATION_TYPE.INFO
        });
        formWebhookNotificationIdRef.current = notificationId;
      }
    },
    [addPendingNotification, currentUser, currentProjectId, pageData, pageFormConfig, finalColumnOptions]
  );

  const handleRowCreation = useCallback(
    async (newRow: GridValidRowModel, addSuccessCallback?: boolean) => {
      let input: RecordItem = {};
      const hasFormDBBypass = pageFormConfig?.additionalConfig?.byPassDbInsert;
      formFieldsArray?.forEach((formCol) => {
        if (formCol.column?.id && (formCol.columnInitValue || formCol.column?.views?.[ViewOption.FORM]?.isHidden)) {
          input[formCol.column.id] = formCol.columnInitValue;
        }
      });
      input = { ...input, ...omit(newRow, ["id", "sortOrder"]) };
      if (config?.additionalConfig?.sortDragAndDrop?.columnToUpdate) {
        input[config?.additionalConfig.sortDragAndDrop.columnToUpdate] = newRow.sortOrder;
      }

      let newRecord;
      try {
        if (hasFormDBBypass) {
          submitRowToFormWebhook(input);
          return;
        }
        if (addSuccessCallback) {
          newRecord = await onFormSubmitWithOptions(input, {
            onSuccess: onInlineRecordCreatedSuccess
          });
          if (!!newRecord) {
            rowsSortOrderRef.current = {
              ...rowsSortOrderRef.current,
              [newRecord.id]: newRow.sortOrder
            };
            toast.success("Record saved successfully");
          }
          return;
        }
        newRecord = await onFormSubmitWithRecord(input);
        if (!!newRecord) {
          rowsSortOrderRef.current = {
            ...rowsSortOrderRef.current,
            [newRecord.id]: newRow.sortOrder
          };
          toast.success("Record saved successfully");
        } else {
          toast.error("Error saving record");
        }
      } catch (err: any) {
        logError({
          error: err as Error,
          message: err.message || "Error saving record in DataGrid",
          source: "DataGrid - handleRowCreation",
          type: ERROR_TYPES.STAFF_TABLE,
          url: window.location.href,
          additionalInfo: {
            newRow
          }
        });
        toast.error("Error saving record");
      }
      return newRecord;
    },
    [
      formFieldsArray,
      onFormSubmitWithRecord,
      config?.additionalConfig?.sortDragAndDrop?.columnToUpdate,
      onFormSubmitWithOptions,
      onInlineRecordCreatedSuccess,
      pageFormConfig,
      submitRowToFormWebhook,
      logError
    ]
  );

  const handleNewRowAdd = useCallback(
    async (row?: RecordItem) => {
      const hasFormDBBypass = pageFormConfig?.additionalConfig?.byPassDbInsert;
      if ((!!isJoinTable && (inlineRows?.length || 0) >= 1) || (hasFormDBBypass && showLoaderRowRef?.current)) {
        toast.error("Only one row can be added to this table at a time.");
        return;
      }
      if (isJoinTable && !compositeKeysColumn) {
        toast.error("Join table must have an editable column for the composite key.");
        return;
      }
      if (hasFormDBBypass && !pageFormConfig?.additionalConfig?.waitForSuccess) {
        toast.error("Form webhook must have wait for success set to add a new row to the table.");
        return;
      }

      const isPopperNewRow = row?.type === "click" && anchorRowId && !row?.id;
      const finalRowId = isPopperNewRow ? anchorRowId : row?.id;
      const rowIndex = finalGridRows?.findIndex((row) => row.id == finalRowId);
      const dummyRow = isPopperNewRow && rowIndex ? finalGridRows?.[rowIndex] : row?.originalRow;
      let finalSortOrder = new Date().toISOString();
      if (isPopperNewRow && dummyRow?.sortOrder && rowIndex !== undefined && (rowIndex || -1) > -1) {
        // We need a rank between the two rows
        const beforeRank = dummyRow?.sortOrder;
        const afterRank = finalGridRows?.[rowIndex + 1]?.["sortOrder"];
        finalSortOrder = LexoRank.between(beforeRank, afterRank)?.toString() || finalSortOrder;
      }

      const newInlineRowId = inlineRows?.length
        ? `${NEW_INLINE_ROW_ID}_${inlineRows?.length}`
        : `${NEW_INLINE_ROW_ID}_0`;

      const rowInput: RecordItem = {};
      const newRow: RecordItem = {
        id: newInlineRowId
      };
      if (((anchorRowId || row?.id) && rowIndex !== -1) || dummyRow?.id) {
        // This is a list of all basic columns to create an entry in the DB for the new row
        Object.keys(dummyRow).forEach((colId: string) => {
          // Check if column has any default value
          const col = finalDisplayColumnsById[colId];
          if (!col?.isLookup) {
            if (col?.defaultValues) {
              newRow[colId] = col.defaultValues?.value || col.defaultValues;
            } else {
              newRow[colId] = null;
            }

            if (col && col?.isEditable) {
              rowInput[colId] = newRow[colId];
            }
          } else {
            newRow[colId] = null;
          }
        });
        newRow.id = newInlineRowId;
        if (rowGroupingModel.length) {
          const finalRowToCheck = isPopperNewRow ? dummyRow : row;
          rowGroupingModel.forEach((model) => {
            const groupByCol = finalColumnOptions?.find((col) => col.id === model);
            if (groupByCol && finalRowToCheck?.id && finalRowToCheck[groupByCol.id]) {
              newRow[groupByCol.id] = groupByCol?.isLookup
                ? Array.isArray(finalRowToCheck[groupByCol.id])
                  ? finalRowToCheck[groupByCol.id].map((item: RecordItem) => ({
                      ...item,
                      id: item?.joinTableId || item?.id
                    }))
                  : {
                      ...finalRowToCheck[groupByCol.id],
                      id: finalRowToCheck[groupByCol.id]?.joinTableId || finalRowToCheck[groupByCol.id]?.id
                    }
                : finalRowToCheck[groupByCol.id];
              newRow.originalRow = finalGridRows?.[rowIndex || -1].originalRow;
              if (groupByCol?.isEditable) {
                rowInput[groupByCol.id] = newRow[groupByCol.id];
              }
            }
          });
        }
        newRow["sortOrder"] = finalSortOrder;
        rowsSortOrderRef.current = {
          ...rowsSortOrderRef.current,
          [newInlineRowId]: finalSortOrder
        };
        if (!hasFormDBBypass) {
          const updatedInlineRows = inlineRows?.length ? [...inlineRows, { ...newRow, id: newInlineRowId }] : [newRow];
          setInlineRows(updatedInlineRows);
        }
      }

      if (!isJoinTable || hasFormDBBypass) {
        if (hasFormDBBypass) {
          showLoaderRowRef.current = true;
          // We add a loader row
          const updatedInlineRows = inlineRows?.length
            ? [...inlineRows, { ...dummyRow, id: NEW_LOADER_INLINE_ROW_ID, sortOrder: finalSortOrder }]
            : [{ ...dummyRow, id: NEW_LOADER_INLINE_ROW_ID, sortOrder: finalSortOrder }];
          setInlineRows(updatedInlineRows);
        }
        handleRowCreation(newRow, true);
        return;
      }
      setEditCellRef.current = newInlineRowId;
    },
    [
      finalGridRows,
      anchorRowId,
      rowGroupingModel,
      finalColumnOptions,
      inlineRows,
      finalDisplayColumnsById,
      isJoinTable,
      compositeKeysColumn,
      handleRowCreation,
      pageFormConfig
    ]
  );

  const getColFormattingStylesValue = useCallback(
    (params: RecordItem) => {
      if (params.rowNode?.type === "group" || !params.colDef?.field) return;
      const col = finalDisplayColumnsById[params.colDef.field];
      const columnColor = columnColors?.find((columnColor) => columnColor.column === col.id);

      if (columnColor?.color || columnColor?.textColor) {
        const finalStyles: {
          backgroundColor?: string;
          color?: string;
        } = {};
        if (columnColor.color) {
          finalStyles.backgroundColor = columnColor.color;
        }
        if (columnColor.textColor) {
          finalStyles.color = columnColor.textColor;
        }
        return finalStyles;
      }

      const rules = conditionalFormatting?.filter((rule) => rule.ruleConfig?.applyTo === col.id);
      const ruleStyles = getRuleStyles(rules, params.row, finalColumnOptions || []);

      return ruleStyles;
    },
    [finalDisplayColumnsById, columnColors, conditionalFormatting, finalColumnOptions]
  );

  const renderColumnCell = useCallback(
    (params: RecordItem) => {
      const col = finalDisplayColumnsById[params?.field];
      let additionalValueFields;
      if (col && col.conditionalViewRules?.ruleConfig?.views?.includes(ViewOption.GRID)) {
        const checkRulePassed = (colRule: ColumnConditionalRule) => {
          const colToEvaluate = colRule?.columnId ? finalDisplayColumnsById?.[colRule?.columnId] : undefined;
          if (!colToEvaluate) return false;
          const visibility = getVisibilityByColumnRule(
            colRule,
            col.conditionalViewRules as ColumnRule,
            colToEvaluate,
            params.row?.[colRule?.columnId as string],
            ViewOption.GRID
          );

          if (visibility === col.conditionalViewRules?.ruleConfig?.visibility) {
            return true;
          }
          return false;
        };

        if (
          getFinalVisibilityFromColumnRule({
            checkRulePassed,
            columnRule: col.conditionalViewRules
          }) === COLUMN_CONDITIONAL_VISIBILITY.HIDE
        ) {
          return null;
        }
      }

      if (col) {
        const rules = conditionalFormatting?.filter((rule) => rule.ruleConfig?.applyTo === col.id);
        additionalValueFields = getAdditionValueFieldsFromColumnRules(rules, params.row, finalColumnOptions || []);
        const columnColor = columnColors?.find((columnColor) => columnColor.column === col.id);
        if (columnColor?.textColor && !additionalValueFields.textColor) {
          additionalValueFields.textColor = columnColor.textColor;
        }
      }
      if (params.aggregation) {
        if (
          col &&
          AGGREGATE_COLUMN_TYPES.includes(col.type) &&
          col.cellConfig?.isAggregate &&
          !!col.cellConfig?.aggregateFunction?.isCombinedAggregate
        ) {
          return col.type === CellType.CURRENCY
            ? currencyFormatter.format(finalAggregateData?.[COMBINED_AGGREGATE_KEY]?.[col.id] || 0)
            : finalAggregateData?.[COMBINED_AGGREGATE_KEY]?.[col.id] || 0;
        }
        return (
          <div className={clsx("flex w-full", params.rowNode.type === "group" && "font-semibold")}>
            {col.type === CellType.CURRENCY ? currencyFormatter.format(params.value) : params.value}
          </div>
        );
      }

      if (params.id === GRID_FOOTER_COL_DEF) {
        return <CellEmpty />;
      }

      const styles = getColFormattingStylesValue(params);
      const wrapperClassName = params.rowNode.type !== "group" && params.colDef?.field ? "p-2.5" : "";
      if (
        col &&
        AGGREGATE_COLUMN_TYPES.includes(col.type) &&
        col.cellConfig?.isAggregate &&
        !col.cellConfig?.aggregateFunction?.isCombinedAggregate
      ) {
        if (aggrLoading) {
          return "Loading..";
        }

        return (
          <GridCellWrapper style={styles} className={wrapperClassName}>
            <CellGroup
              key={`${col.id}_${params.row.id}`}
              column={col}
              type={col.type}
              value={finalAggregateData?.[params.row.id]?.[col.id] || 0}
              className={clsx("my-4 w-full", !col.colIdx ? "cursor-pointer" : "")}
              classNameContainer="w-full"
              isMultiple={col.isMultiple}
              row={params.row}
              inGrid
              allColumns={finalColumnOptions || []}
              actions={actions}
              additionalValueFields={additionalValueFields}
              isInTable={!inDetailView}
              onEditDisabled={onEditDisabled}
              isLoading={params.row.id === NEW_LOADER_INLINE_ROW_ID}
            />
          </GridCellWrapper>
        );
      }

      if (`${params.id}`.startsWith(NEW_ROW_ADD_ROW) || `${params.row?.id}`.startsWith(NEW_ROW_ADD_ROW)) {
        const colIndx = col ? finalDisplayColumns?.findIndex((colVal) => colVal.id === col.id) : -1;
        if ((rowGroupingModel.length && colIndx > 1) || (!rowGroupingModel.length && colIndx !== 0)) return null;

        return (
          <div className="flex w-full items-center justify-between gap-x-2">
            <div>
              <div className="flex flex-row items-center gap-y-0.5">
                <IconButton icon={PlusIcon} />
                <span className="ml-5 text-sm font-semibold">New Row</span>
              </div>
            </div>
          </div>
        );
      }

      if (params.rowNode.type === "group") {
        const rows = apiRef.current?.getRowGroupChildren({ groupId: params.id });
        const row = finalTableData.find((rowData) => rowData.id === rows[0]);
        const fieldValCol = finalDisplayColumnsById[params.rowNode.groupingField];
        if (params.value === EMPTY_GROUP_FIELD) {
          return <CellText value={params.value} isHeader />;
        }
        if (row && fieldValCol?.id && !col) {
          const rules = conditionalFormatting?.filter((rule) => rule.ruleConfig?.applyTo === fieldValCol.id);
          additionalValueFields = getAdditionValueFieldsFromColumnRules(rules, params.row, finalColumnOptions || []);
          const columnColor = columnColors?.find((columnColor) => columnColor.column === fieldValCol.id);
          if (columnColor?.textColor && !additionalValueFields.textColor) {
            additionalValueFields.textColor = columnColor.textColor;
          }
          return (
            <GroupCell
              count={rows?.length}
              row={row}
              col={fieldValCol}
              columnOptions={finalColumnOptions || []}
              className={clsx(!fieldValCol?.colIdx ? "cursor-pointer" : "")}
              rowDensityType={rowDensityByTableSlug?.[currentSlug]?.type || DensityType.STANDARD}
              handleEmailClick={handleEmailClick}
              handleCellDetailActionClick={openRecordInNestedView}
              config={config}
              additionalValueFields={additionalValueFields}
              handleAddItem={isParentRecordLocked || !inlineCreationEnabled ? undefined : handleNewRowAdd}
            />
          );
        }
        return null;
      }

      const handleCellGroupEditValue = (
        value: any,
        scopeId?: string,
        valueCol?: TableColumnType,
        additionalValues?: any
      ) => handleRecordEdit(value, params.row, col, additionalValues);

      if (col && col?.cellConfig?.action) {
        const action = actions?.find((action) => action.id === col.cellConfig?.action);

        if (!action) return null;

        return (
          <GridCellWrapper style={styles} className={wrapperClassName}>
            <CellGroup
              key={`${col.id}_${params.row.id}`}
              column={col}
              type={col.type}
              value={params.row[col.id]}
              className={clsx("my-4 w-full", !col.colIdx ? "cursor-pointer" : "")}
              classNameContainer="w-full"
              isMultiple={col.isMultiple}
              row={params.row}
              actionProps={{
                actions: [action],
                tableName,
                tablePath: `/${currentSlug}`,
                formattedRecord: params.row.formattedRecordVal,
                record: params.row,
                parentRecordSlug: parentRecordSlug
              }}
              inGrid
              allColumns={finalColumnOptions || []}
              actions={actions}
              additionalValueFields={additionalValueFields}
              isInTable={!inDetailView}
              onEditDisabled={onEditDisabled}
              isLoading={params.row.id === NEW_LOADER_INLINE_ROW_ID}
            />
          </GridCellWrapper>
        );
      }

      const useColEditable =
        (`${params.row?.id || ""}`?.startsWith(NEW_INLINE_ROW_ID) &&
          rowGroupingModel.length &&
          !isParentRecordLocked &&
          !params.row?.is_locked &&
          !params.row?.originalRow?.is_locked) ||
        (col.isEditable && [CellType.BOOLEAN].includes(col.type));

      const isEditableAndLocked =
        col?.isEditable && (isParentRecordLocked || params.row?.is_locked || params.row?.originalRow?.is_locked);
      const isEditableAndNotLocked =
        col?.isEditable && !isParentRecordLocked && !params.row?.is_locked && !params.row?.originalRow?.is_locked;
      const defaultTablePage = getDefaultTablePageForColumn(col, defaultTablePages);

      return (
        <GridCellWrapper style={styles} className={wrapperClassName}>
          <CellGroup
            key={`${col?.id}_${params.row.id}`}
            column={col?.type === CellType.JSON ? finalColumnOptions?.find((colOpt) => colOpt.id === col.id) : col}
            type={col?.type}
            value={params.row[col?.id]}
            className={clsx(
              "my-4 w-full",
              !col?.colIdx ? "cursor-pointer" : "",
              isEditableAndNotLocked &&
                "rounded-lg px-2 py-3 hover:cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700",
              isEditableAndLocked && "cursor-not-allowed px-2"
            )}
            classNameContainer="w-full"
            size={
              rowDensityByTableSlug ? getSizeBasedInRowDensity(rowDensityByTableSlug?.[currentSlug]?.type) : undefined
            }
            onEmailClick={handleEmailClick}
            onCellDetailActionClick={openRecordInNestedView}
            isMultiple={col?.isMultiple}
            row={params.row.originalRow || params.row}
            inGrid
            allColumns={finalColumnOptions || []}
            isEditable={useColEditable ? col?.isEditable : false}
            onEdit={handleCellGroupEditValue}
            columnTableName={tableName}
            columnTablePath={`/${currentSlug}`}
            actions={actions}
            autoHeight={config?.additionalConfig?.enableAutoHeight}
            additionalValueFields={additionalValueFields}
            gridInlineEditable={isEditableAndNotLocked}
            isInTable={!inDetailView}
            onEditDisabled={onEditDisabled}
            isLoading={params.row.id === NEW_LOADER_INLINE_ROW_ID}
            loadOnClick
            defaultTablePage={defaultTablePage}
          />
        </GridCellWrapper>
      );
    },
    [
      finalTableData,
      finalDisplayColumnsById,
      apiRef,
      finalColumnOptions,
      config,
      currentSlug,
      openRecordInNestedView,
      handleEmailClick,
      rowGroupingModel,
      tableName,
      rowDensityByTableSlug,
      handleRecordEdit,
      isParentRecordLocked,
      actions,
      conditionalFormatting,
      inDetailView,
      inlineCreationEnabled,
      handleNewRowAdd,
      finalDisplayColumns,
      onEditDisabled,
      parentRecordSlug,
      columnColors,
      aggrLoading,
      finalAggregateData,
      defaultTablePages,
      getColFormattingStylesValue
    ]
  );

  const renderExpandCell = useCallback(
    (params: any) => {
      if (
        params.rowNode.type === "group" ||
        params.id === GRID_FOOTER_COL_DEF ||
        rowSelectionModel?.length ||
        `${params.id}`.startsWith(NEW_ROW_ADD_ROW)
      )
        return null;

      if (config?.disableExpand) {
        return <span id="add-row-anchor" className="self-end" />;
      }
      return (
        <div
          onClick={() => {
            handleRowClick?.(params.row.originalRow);
            setAnchorEl(null);
          }}
          className="datagrid-row-expand flex h-full cursor-pointer items-center hover:!opacity-100"
        >
          <span id="add-row-anchor" className="self-end" />
          <Button
            label=""
            appearance={ButtonAppearance.SECONDARY}
            icon={ExpandIcon}
            className="lightpop whitespace-nowrap bg-white px-2 dark:bg-black"
          />
        </div>
      );
    },
    [handleRowClick, rowSelectionModel?.length, config?.disableExpand]
  );

  const expandColumn: GridColDef = useMemo(
    () => ({
      field: GRID_EXPAND_COL_DEF,
      width: 50,
      resizable: false,
      sortable: false,
      filterable: false,
      aggregable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      getApplyQuickFilterFn: undefined,
      align: "center",
      renderHeader: () => null,
      renderCell: renderExpandCell
    }),
    [renderExpandCell]
  );

  const renderCheckBoxColumn = useCallback((params: GridRenderCellParams) => {
    if (`${params.id}`.startsWith(NEW_ROW_ADD_ROW) || `${params.row?.id}`.startsWith(NEW_ROW_ADD_ROW)) {
      return null;
    }
    const Cell = GRID_CHECKBOX_SELECTION_COL_DEF.renderCell;
    if (Cell) {
      return <Cell {...params} />;
    }
    return null;
  }, []);

  const checkboxColumn: GridColDef = useMemo(
    () => ({
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: isMobile ? 48 : 60,
      minWidth: isMobile ? 48 : 60,
      maxWidth: isMobile ? 48 : 60,
      cellClassName: "!px-0 !mx-0 idCell",
      headerClassName: "!px-0 !mx-0 idCell headerCell",
      disableExport: true,
      renderCell: renderCheckBoxColumn
    }),
    [isMobile, renderCheckBoxColumn]
  );

  const renderReorderColumn = useCallback((params: GridRenderCellParams) => {
    if (`${params.id}`.startsWith(NEW_ROW_ADD_ROW) || `${params.row?.id}`.startsWith(NEW_ROW_ADD_ROW)) {
      return null;
    }
    const Cell = GRID_REORDER_COL_DEF.renderCell;
    if (Cell) {
      return <Cell {...params} />;
    }
    return null;
  }, []);

  const reOrderColumn: GridColDef = useMemo(
    () => ({
      ...GRID_REORDER_COL_DEF,
      width: 40,
      cellClassName: "!p-0",
      renderCell: renderReorderColumn
    }),
    [renderReorderColumn]
  );

  const renderEditCell = useCallback(
    (params: GridCellParams) => {
      const col = finalDisplayColumnsById[params.colDef?.field];
      if (col.isEditable && !isParentRecordLocked && !params.row?.is_locked && !params.row?.originalRow?.is_locked) {
        return (
          <CellGroupEdit
            params={params}
            col={col}
            rowDensity={rowDensityByTableSlug?.[currentSlug]}
            handleEmailClick={handleEmailClick}
            handleCellDetailActionClick={openRecordInNestedView}
            idx={col.colIdx}
            onRecordEdit={handleRecordEdit}
            allColumns={finalColumnOptions || []}
            editMode={GridEditModes.Cell}
            isFormulaTable={isFormulaTable}
            inDetailView={inDetailView}
            onEditDisabled={onEditDisabled}
          />
        );
      }
    },
    [
      handleEmailClick,
      handleRecordEdit,
      openRecordInNestedView,
      finalDisplayColumnsById,
      finalColumnOptions,
      currentSlug,
      rowDensityByTableSlug,
      isParentRecordLocked,
      isFormulaTable,
      inDetailView,
      onEditDisabled
    ]
  );

  const getValueFormatter: GridValueFormatter = useCallback(
    (value, row, column) => {
      const col = finalDisplayColumnsById[column.field];
      const val = getTextFromColumn(col, value);

      if (col.columnGroupBySortOrder) {
        return getTextFromColumnWithFieldSelected(col, value, col.columnGroupBySortOrder);
      }
      return val ? val : undefined;
    },
    [finalDisplayColumnsById]
  );

  const groupByColumn = finalColumnOptions?.find((col) => col.views?.[ViewOption.GRID]?.isGroupBy);

  const getCellGroupValue: GridGroupingValueGetter = useCallback(
    (value, row, colDef) => {
      const col = finalDisplayColumnsById[colDef.field];
      const val = getTextFromColumn(col, row[col.id]);

      if (col.columnGroupBySortOrder) {
        return getTextFromColumnWithFieldSelected(col, row[col.id], col.columnGroupBySortOrder);
      }

      return val ? val : EMPTY_GROUP_FIELD;
    },
    [finalDisplayColumnsById]
  );

  const renderHeader = useCallback(
    (params: GridColumnHeaderParams<any, any, any>) => {
      const col = params?.colDef?.field ? finalDisplayColumnsById[params.colDef.field] : undefined;

      if (!col) return null;

      return (
        <div className="flex w-full flex-nowrap items-center">
          <div className="MuiDataGrid-columnHeaderTitle overflow-hidden text-ellipsis whitespace-nowrap">
            {col.header}
          </div>
          {!!col.isFormula && <FormulaIcon className="ml-1 hidden group-hover/header:inline-flex" />}
          {!!col.isAdminColumn && <AdminIcon className="ml-1 hidden group-hover/header:inline-flex" />}
          {!!col.isChild && !!currentUser?.is_admin && (
            <Tooltip title="Child column" disableInteractive>
              <div>
                <ChildIcon className="ml-2 inline-flex h-4 w-4 text-gray-400" />
              </div>
            </Tooltip>
          )}
          {!!isParentPage && !!currentUser?.is_admin && (
            <Tooltip title="Parent column" disableInteractive>
              <div>
                <ParentIcon className="ml-2 inline-flex h-4 w-4 text-gray-400" />
              </div>
            </Tooltip>
          )}
          {!!col.description ? (
            <Tooltip title={col.description} disableInteractive>
              <div className="absolute right-0">
                <InfoIcon className="MuiDataGrid-header-description text-secondary hidden h-4 w-4" />
              </div>
            </Tooltip>
          ) : null}
        </div>
      );
    },
    [finalDisplayColumnsById, currentUser, isParentPage]
  );

  // Here we are some conditions to add styles in cells
  // only if the rule apply to one column, for styles for all row
  // check CustomGridRow
  const getCellClassNameValue = useCallback((params: RecordItem) => {
    if (params.rowNode?.type === "group" || !params.colDef?.field) return "!outline-none";
    return "!p-0";
  }, []);

  const valueGetter: GridValueGetter = useCallback(
    (value, row, column) => {
      const col = finalDisplayColumnsById[column.field];
      if ((col.type === CellType.CURRENCY || col.isFormula) && col.isLookup) {
        return getTextFromColumnId(col.id, [col], row.originalRow);
      }
      return row[col.id];
    },
    [finalDisplayColumnsById]
  );

  const onActionSuccessCallback = useCallback(
    (record: RecordItem, actionName: UI_ACTIONS) => {
      if (!apiRef?.current || !record) return;
      const finalPage = isPageActiveFiltered ? filteredPage : page;
      if (actionName === UI_ACTIONS.DELETE && (finalPage || 0) > 1) {
        apiRef.current?.updateRows([{ id: record.id, _action: "delete" }]);
      }
    },
    [apiRef, isPageActiveFiltered, filteredPage, page]
  );

  const renderActionHanderCell = useCallback(
    (params: GridCellParams) => {
      if (params.id === GRID_FOOTER_COL_DEF) return null;

      if (params.rowNode.type === "group") {
        if (!config?.additionalConfig?.enableGroupWebhookAction || !config?.additionalConfig?.groupWebhookActionUrl)
          return null;

        const rows = apiRef.current?.getRowGroupChildren({ groupId: params.id });
        const fieldValCol = params.rowNode.groupingField
          ? finalDisplayColumnsById[params.rowNode.groupingField]
          : undefined;
        const groupingKey = params.rowNode.groupingKey;
        return (
          <div className="flex h-full w-full items-center justify-center">
            <Tooltip title="Send to Webhook">
              <IconButton
                icon={ExternalLinkIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  handleGroupByWebhookActionClick({ rows, groupByColumn: fieldValCol, groupingKey });
                }}
              />
            </Tooltip>
          </div>
        );
      }

      const actionsWithHiddenProperty = finalActions.map((action) => {
        const visibilityRule = action?.conditionalViewRules;
        if (visibilityRule && visibilityRule.columnRules) {
          const checkRulePassed = (colRule: ColumnConditionalRule) => {
            if (!colRule.columnId) return false;
            const col = columnOptions?.find((col) => col.id === colRule.columnId);
            if (!col) return false;

            const valueToEvaluate = getColumnValueFromRowData({
              row: { ...params.row.originalRow, ...additionDataFields },
              col,
              colOptions: columnOptions || [],
              recordTypesData,
              extendedSchema: finalBaseSchema
            });
            const finalVisibility = getActionVisibilityRule(col, colRule, visibilityRule, valueToEvaluate);

            if (finalVisibility === visibilityRule.ruleConfig?.visibility) {
              return true;
            }
            return false;
          };
          const isHidden =
            getFinalVisibilityFromVisibilityRule({ checkRulePassed, visibilityRule }) ===
            COLUMN_CONDITIONAL_VISIBILITY.HIDE;

          return { ...action, isHidden: isHidden };
        }
        return action;
      });

      const filteredActions: ViewAction[] = [];

      actionsWithHiddenProperty.forEach((action, index) => {
        if (action && !(actionsWithHiddenProperty?.[index - 1]?.isHidden && action.isHidden)) {
          filteredActions.push(action as ViewAction);
        }
      });
      params.row;
      return (
        <ActionsHandler
          actions={filteredActions}
          {...params}
          record={params.row.originalRow || params.row}
          formattedRecord={params.row.formattedRecordVal}
          tableName={tableName}
          tablePath={`/${currentSlug}`}
          iconProps={{ color: "transparent", size: "xs" }}
          className="flex h-full w-full items-center justify-center"
          onActionsClick={onActionsClick}
          isAdmin={isAdmin}
          isLocked={!!params.row.originalRow?.is_locked || !!params.row?.is_locked}
          inView={ViewOption.GRID}
          showExposedActions={filteredActions.some((action) => action.isExposed)}
          onActionSuccessCallback={onActionSuccessCallback}
        />
      );
    },
    [
      finalActions,
      tableName,
      onActionsClick,
      isAdmin,
      currentSlug,
      columnOptions,
      recordTypesData,
      finalBaseSchema,
      additionDataFields,
      onActionSuccessCallback,
      handleGroupByWebhookActionClick,
      apiRef,
      config?.additionalConfig?.enableGroupWebhookAction,
      config?.additionalConfig?.groupWebhookActionUrl,
      finalDisplayColumnsById
    ]
  );

  const formattedColumns: GridColDef[] = useMemo(() => {
    const sortedColumns = sortBy(
      finalDisplayColumns.filter(
        (col) =>
          (col.views?.[ViewOption.GRID]?.id && !col.views?.[ViewOption.GRID]?.isHidden) || col.cellConfig?.isAggregate
      ),
      (col) => displayColumnSortedIds.indexOf(col.id)
    ).map((col) => {
      const isEditable =
        col.isEditable && InlineEditingCellTypes.includes(col.type) && col.enableInlineEditing && !isParentRecordLocked;
      const colType = getType(col);
      const colDef: GridColDef = {
        field: getColumnFieldIdentifier(col),
        headerName: col.header,
        description: col.description,
        headerAlign: "left",
        headerClassName: "group/header",
        type: colType,
        display: "flex",
        sortable:
          inlineRows?.length || enableSortDragAndDrop
            ? false
            : pageViewFilter
              ? !!col.hasFilter
              : (!col.isLookup || (col.isLookup && !!col.lookupPath?.["0"]?.lookupForeignKey)) &&
                ![CellType.GENERIC_CELL, col.type === CellType.BUTTON].includes(col.type),
        editable: isEditable,
        disableExport: col.type === CellType.BUTTON && !col.cellConfig?.isLink, // disable export for buttons which are not link
        valueGetter,
        groupingValueGetter: getCellGroupValue,
        renderCell: col.renderCell ? col.renderCell : renderColumnCell,
        cellClassName: getCellClassNameValue,
        renderHeader,
        aggregable: col.type === CellType.CURRENCY || col.isFormula,
        colSpan: (value, row) => {
          if (`${row.id}`.startsWith(NEW_ROW_ADD_ROW)) {
            return 100;
          }
          return undefined;
        },
        ...getStylesForCol(col, gridViewWidth?.[col.id])
      };
      // Only show native grid input for formula tables
      if (colType !== "number") {
        if (isEditable) {
          colDef.renderEditCell = renderEditCell;
        }
        colDef.valueFormatter = getValueFormatter;
      }
      return colDef;
    });

    const actionColumn = {
      field: "actions",
      headerName: "Actions",
      editable: false,
      disableExport: true,
      renderCell: renderActionHanderCell
    };

    const showActions = actions.length && !inAddMany;

    const finalColumns = [reOrderColumn, checkboxColumn];

    if (!isMobile && !inAddMany && (!config?.disableExpand || config.additionalConfig?.showInlineFloatingButton)) {
      finalColumns.push(expandColumn);
    }

    finalColumns.push(...sortedColumns);

    if (showActions) {
      finalColumns.push(actionColumn);
    }

    return finalColumns;
  }, [
    pageViewFilter,
    finalDisplayColumns,
    inAddMany,
    config,
    actions.length,
    displayColumnSortedIds,
    gridViewWidth,
    enableSortDragAndDrop,
    isMobile,
    checkboxColumn,
    expandColumn,
    renderColumnCell,
    renderEditCell,
    getCellGroupValue,
    renderHeader,
    getCellClassNameValue,
    getValueFormatter,
    valueGetter,
    renderActionHanderCell,
    isParentRecordLocked,
    inlineRows?.length,
    reOrderColumn
  ]);

  const columnGroupingModel: GridColumnGroupingModel = useMemo(() => {
    if (!config?.additionalConfig?.enableColumnGroups || !config?.additionalConfig?.columnGroups) return [];
    return config.additionalConfig.columnGroups.map((colGroup) => ({
      groupId: colGroup.id,
      headerName: colGroup.title,
      children: colGroup.columns.map((colId) => ({ field: colId }))
    }));
  }, [config?.additionalConfig?.enableColumnGroups, config?.additionalConfig?.columnGroups]);

  const handleRowModesModelChange = useCallback((newModel: GridRowModesModel) => {
    setRowModesModel(newModel);
  }, []);

  const handleOnError = useCallback(
    (error: Error, info: { componentStack: string }) => {
      logError({
        error: error,
        source: "DataGridView",
        message: error.message || "Error displaying data grid view",
        type: ERROR_TYPES.COMPONENT,
        url: window.location.href,
        additionalInfo: {
          stack: info.componentStack,
          tableSlug: currentSlug,
          inAddMany,
          inDetailView,
          inSidebar,
          tableName,
          parentRecordId,
          parentRecordSlug,
          tabSlug
        }
      });
    },
    [logError, currentSlug, inAddMany, inDetailView, inSidebar, tableName, parentRecordId, parentRecordSlug, tabSlug]
  );

  useEffect(() => {
    if (currentSlug === "task-library" && finalFilters?.length) {
      setTotalCount(currentSlug, filterCount, parentRecordId);
      return;
    }
    if (isSearchActive && searchTotalCount) {
      setTotalCount(finalSlug, searchTotalCount, parentRecordId);
      return;
    }
    if (typeof totalCount === "number" && isFetched) {
      setTotalCount(finalSlug, totalCount, parentRecordId);
    }
  }, [
    currentSlug,
    totalCount,
    setTotalCount,
    parentRecordId,
    filterCount,
    finalFilters?.length,
    isFetched,
    finalSlug,
    searchTotalCount,
    isSearchActive
  ]);

  useEffect(() => {
    if (!tableData?.length) return;

    setDataFirstRowsIds(
      currentSlug,
      tableData
        .slice(0, 20)
        .map((item) => item?.id)
        .filter((id) => !!id)
    );
  }, [currentSlug, tableData, setDataFirstRowsIds]);

  useEffect(() => {
    if (!finalDataColumns?.length || !tableData?.length) return;
    const columnsWithWriteRollup = finalDataColumns.filter((col) => col.writeRollupConfig?.idSource);
    if (!columnsWithWriteRollup?.length) return;
    processColumnsAndUpdateTableData({
      columnsWithWriteRollup,
      allColumns: finalColumnOptions || [],
      tableData
    });
  }, [currentSlug, tableData, finalDataColumns, processColumnsAndUpdateTableData, finalColumnOptions]);

  useEffect(() => {
    if (inlineCreationEnabled || isSearchResultShown || !formattedColumns?.length) return;
    let sortModelItems: GridSortModel = [];
    if (sortingByTableSlug?.[currentSlug]?.length) {
      sortModelItems =
        sortingByTableSlug?.[currentSlug]?.map((s) => ({
          field: s.col?.id || s.id,
          sort: s.desc ? "desc" : "asc"
        })) || [];
    }

    if (rowGroupingModel?.length) {
      const groupBySortModel: GridSortModel = [];
      rowGroupingModel.forEach((model) => {
        const groupByCol = finalColumnOptions?.find((col) => col.id === model);

        if (groupByCol?.columnGroupBySortOrder) {
          groupBySortModel.push({
            field: getGroupByColumnFieldIdentifier(groupByCol.id),
            sort: "asc"
          });
        }
      });
      sortModelItems.splice(0, 0, ...groupBySortModel);
    }
    // Don't add default ID sort to sort model as this is the case where ID column does not exist in the grid
    const finalSortModelItems = sortModelItems.filter((sort) => sort.field !== "id_sort");
    if (!isEqual(finalSortModelItems, sortModel)) {
      setSortModel(finalSortModelItems);
    }
  }, [
    sortingByTableSlug,
    currentSlug,
    inlineCreationEnabled,
    rowGroupingModel,
    finalColumnOptions,
    isSearchResultShown,
    sortModel,
    formattedColumns
  ]);

  const onSortModelChange = useCallback(
    (updatedSort: GridSortModel) => {
      if (!updatedSort.length) return;
      const sortedIds: Record<string, GridSortDirection> = updatedSort.reduce(
        (acc, val) => {
          acc[val.field] = val.sort;
          return acc;
        },
        {} as Record<string, GridSortDirection>
      );

      const updatedCols = finalColumnOptions?.filter((col) => sortedIds[col.id]);
      if (!updatedCols?.length) return;
      // Also reset sort order
      rowsSortOrderRef.current = {};
      handleUpdateSorting(
        updatedCols.map((col) => ({
          ...col,
          desc: sortedIds[col.id] === "desc",
          col: col,
          sortSlug: currentSlug
        }))
      );
      const currentPage = paginationByTableSlug?.[currentSlug].currentPage;
      if (currentPage !== 1) {
        apiRef.current?.scroll({ top: 0, left: 0 });
      }
    },
    [finalColumnOptions, currentSlug, paginationByTableSlug, apiRef, handleUpdateSorting]
  );

  useEffect(() => {
    if (!finalDisplayColumns || initAggregationModel.current || pageConfigLoading) return;

    const hasFormula = !!finalDisplayColumns.find((col) => col.isFormula);

    if (!config?.additionalConfig?.aggregationBarEnabled && !hasFormula) return;

    const aggregationCols = finalDisplayColumns.filter(
      (col) => (col.type === CellType.CURRENCY || col.isFormula) && !col?.cellConfig?.disableAggregation
    );
    if (!aggregationCols.length) return;

    const model = aggregationCols.reduce((acc: { [key: string]: string }, curr) => {
      const finalKey = curr.id;
      acc[finalKey] = "sum";
      return acc;
    }, {});
    setAggregationModel(model);
    initAggregationModel.current = true;
  }, [finalDisplayColumns, config, pageConfigLoading]);

  const handleAggregationModelChange = useCallback((model: GridAggregationModel) => {
    setAggregationModel(model);
  }, []);

  const handleGroupingModelChange = useCallback(
    (model: GridRowGroupingModel) => {
      const columnsToGroup = model.length ? (model.length > 2 ? model.slice(2) : model) : [];
      setRowGroupingModel((prev) => {
        setPrevRowGroupingModel(prev);
        return columnsToGroup;
      });
      if (!!columnsToGroup.length) {
        const groupColumnDef = columnsToGroup.map((col) => getGroupByColumnFieldIdentifier(col));
        const left = multiSelectEnabled
          ? [GRID_REORDER_COL_DEF.field, GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_EXPAND_COL_DEF].concat(
              isMobile ? [] : groupColumnDef
            )
          : [GRID_REORDER_COL_DEF.field, GRID_EXPAND_COL_DEF].concat(isMobile ? [] : groupColumnDef);
        setPinnedColumns((prev) => {
          const prevLeft = prev.left?.filter((colId) => !left.includes(colId)) || [];

          return {
            ...prev,
            left: [...left, ...prevLeft]
          };
        });
        // Also reset sort order
        rowsSortOrderRef.current = {};
        updateGroupByInfoByTableSlug(
          {
            enabled: true,
            groupedColumns: columnsToGroup,
            firstColumn: firstCol
          },
          currentSlug,
          ViewOption.GRID
        );
      } else {
        const left = [GRID_REORDER_COL_DEF.field, GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_EXPAND_COL_DEF].concat(
          isMobile ? [] : firstCol
        );

        setPinnedColumns((prev) => {
          const prevLeft = prev.left?.filter((colId) => !left.includes(colId)) || [];

          return {
            ...prev,
            left: [...left, ...prevLeft]
          };
        });
        updateGroupByInfoByTableSlug(
          {
            enabled: false,
            groupedColumns: [],
            firstColumn: undefined
          },
          currentSlug,
          ViewOption.GRID
        );
      }
      initVisibilityColumnsApplied.current = false;
    },
    [multiSelectEnabled, firstCol, updateGroupByInfoByTableSlug, currentSlug, isMobile]
  );

  const handleVisibilityChange = useCallback(
    (visibilityUpdated: RecordItem) => {
      updateColumnsVisibilityModelByTableSlug(currentSlug, visibilityUpdated, ViewOption.GRID);
    },
    [currentSlug, updateColumnsVisibilityModelByTableSlug]
  );

  useEffect(() => {
    const finalTotalCount = isPageActiveFiltered ? filterCount : totalCount;
    if (finalTotalCount && finalTotalCount !== paginationByTableSlug?.[currentSlug]?.totalCount) {
      updatePaginationByTableSlug(
        { ...(paginationByTableSlug?.[currentSlug] || {}), totalCount: finalTotalCount },
        currentSlug
      );
    }
  }, [totalCount, updatePaginationByTableSlug, paginationByTableSlug, currentSlug, filterCount, isPageActiveFiltered]);

  useEffect(() => {
    if (!initVisibilityColumnsApplied.current || pageConfigLoading) return;
    if (rowGroupingModel.length) {
      const visibilityUpdated = {
        [firstCol]: false
      };
      rowGroupingModel.forEach((model) => {
        visibilityUpdated[model] = false;
      });

      if (prevRowGroupingModel.length) {
        prevRowGroupingModel.forEach((model) => {
          if (model !== firstCol && !rowGroupingModel.includes(model)) {
            visibilityUpdated[model] = true;
          }
        });
      }

      updateColumnsVisibilityModelByTableSlug(currentSlug, visibilityUpdated, ViewOption.GRID);
    } else {
      const visibilityUpdated = {
        [firstCol]: true
      };

      if (prevRowGroupingModel.length) {
        prevRowGroupingModel.forEach((model) => {
          if (model !== firstCol) {
            visibilityUpdated[model] = true;
          }
        });
      }

      updateColumnsVisibilityModelByTableSlug(currentSlug, visibilityUpdated, ViewOption.GRID);
    }
  }, [
    currentSlug,
    firstCol,
    rowGroupingModel,
    updateColumnsVisibilityModelByTableSlug,
    prevRowGroupingModel,
    pageConfigLoading
  ]);

  useEffect(() => {
    if (!finalDisplayColumns?.length) return;
    const groupCol = finalDisplayColumns.find((col) => !!col.views?.[ViewOption.GRID]?.isGroupBy);
    const groupBySecondCol = finalDisplayColumns.find((col) => !!col.views?.[ViewOption.GRID]?.isGroupBySecond);
    // Added to allow grouping when changed from view config edit
    if (
      initColGroupByApplied.current &&
      pageConfigLoading &&
      groupCol?.id === initColGroupCol.current?.[0] &&
      groupBySecondCol?.id === initColGroupCol.current?.[1]
    ) {
      return;
    }
    initColGroupByApplied.current = true;
    const newGroupByModel = [];
    if (groupCol) {
      newGroupByModel.push(groupCol.id);
      initColGroupCol.current[0] = groupCol.id;
    }
    if (groupBySecondCol) {
      newGroupByModel.push(groupBySecondCol.id);
      if (initColGroupCol.current.length < 1) {
        initColGroupCol.current[0] = "";
      }
      initColGroupCol.current[1] = groupBySecondCol.id;
    }

    if (newGroupByModel.length) {
      // TODO: Fix grouping
      handleGroupingModelChange(newGroupByModel);
    }
  }, [finalDisplayColumns, handleGroupingModelChange, rowGroupingModel.length, pageConfigLoading]);

  const handlePinnedColumnsChange = useCallback((updatedPinnedColumns: GridPinnedColumnFields) => {
    setPinnedColumns(updatedPinnedColumns);
  }, []);

  const handleOnCellClick = useCallback(
    (params: GridCellParams) => {
      if (`${params?.row?.id}`.startsWith(NEW_ROW_ADD_ROW)) {
        handleNewRowAdd(params.row);
        return;
      }
      if (rowSelectionModel?.length && params.rowNode.type !== "group") {
        setRowSelectionModel((prev) => {
          if (prev.includes(params.id)) {
            return prev.filter((id) => id !== params.id);
          }
          return [...prev, params.id];
        });
        return;
      }

      if (params.isEditable) {
        const cellCol = finalDisplayColumnsById[params.field];
        // Cell is already in editable state
        if ([CellType.BOOLEAN].includes(cellCol.type)) {
          return;
        }
        try {
          const cellMode = apiRef.current?.getCellMode(params.id, params.field);
          if (cellMode === "view") {
            apiRef.current?.startCellEditMode({ id: params.id, field: params.field });
            setCellIsEditing(true);
          }
        } catch (error) {}

        return;
      }

      if (!finalDisplayColumns?.length || !tableData || inAddMany || params.rowNode.type === "group") {
        return;
      }

      const col = finalDisplayColumns.find((col) => col.id === params.colDef.field);

      if (col?.type === CellType.FILE && params.row.id !== NEW_INLINE_ROW_ID) {
        handleRowClick?.(params.row as RecordItem, col.id);
      } else if (params.field === finalDisplayColumns[0].id) {
        if (config?.disableExpand) {
          return;
        }

        handleRowClick?.(params.row as RecordItem);
      }

      const index = finalTableData.findIndex((row) => row.id === params.row.id);
      if (totalCount && totalCount > FINAL_INIT_PAGINATION.pageSize) {
        const currentPageUpdated = Math.ceil((index || 1) / FINAL_INIT_PAGINATION.pageSize);

        updatePaginationByTableSlug(
          { ...(paginationByTableSlug?.[currentSlug] || {}), currentPage: currentPageUpdated },
          currentSlug
        );
      }

      const sortedRowIds = apiRef.current.getSortedRowIds().filter((id) => Number.isInteger(id));
      const finalRowIdsToUse = [...pinnedRowsIds.top, ...sortedRowIds];
      const sortedIndex = finalRowIdsToUse.findIndex((id) => id === params.row.id);
      const fromIndex = sortedIndex - 10 > 0 ? sortedIndex - 10 : 0;
      const finalRowIds =
        finalTableData.length <= 20 ? sortedRowIds : finalRowIdsToUse.slice(fromIndex, fromIndex + 20);

      setDataRowsIds(currentSlug, finalRowIds as string[]);
    },
    [
      rowSelectionModel?.length,
      finalDisplayColumns,
      tableData,
      inAddMany,
      finalTableData,
      totalCount,
      apiRef,
      setDataRowsIds,
      currentSlug,
      handleRowClick,
      config?.disableExpand,
      updatePaginationByTableSlug,
      paginationByTableSlug,
      pinnedRowsIds.top,
      FINAL_INIT_PAGINATION.pageSize,
      handleNewRowAdd,
      finalDisplayColumnsById
    ]
  );

  const handleFetchRows = useCallback(
    (params: any) => {
      let finalTotalCount = totalCount || 0;
      if (currentSlug === "task-library" && finalFilters?.length) {
        finalTotalCount = filterCount || 0;
      }
      // When the group by is enable it adds all group rows to visibleRowsCount
      // making it incorrect
      const finalDataCount = groupByInfoByTableSlug?.[currentSlug]?.[ViewOption.GRID]?.enabled
        ? finalTableData?.length
        : params.visibleRowsCount;
      const currentPage = paginationByTableSlug?.[currentSlug]?.currentPage || 1;
      const fetchMoreRows =
        finalDataCount &&
        finalTotalCount > currentPage * FINAL_INIT_PAGINATION.pageSize &&
        finalTotalCount > FINAL_INIT_PAGINATION.pageSize;
      if (fetchMoreRows) {
        updatePaginationByTableSlug(
          {
            ...paginationByTableSlug?.[currentSlug],
            currentPage: (paginationByTableSlug?.[currentSlug].currentPage || 1) + 1
          },
          currentSlug
        );
      }
    },
    [
      groupByInfoByTableSlug,
      currentSlug,
      finalTableData?.length,
      totalCount,
      updatePaginationByTableSlug,
      paginationByTableSlug,
      filterCount,
      finalFilters?.length,
      FINAL_INIT_PAGINATION.pageSize
    ]
  );

  const isGroupExpandedByDefault = useCallback(
    (node: GridGroupNode) => {
      return groupExpandedState?.[node.id] === false ? false : true;
    },
    [groupExpandedState]
  );

  // Adds first page data to the grid
  useEffect(() => {
    if (!tableData || inlineRows?.length) return;
    const finalPage = isSearchActive ? currentPaginationPage || 1 : isPageActiveFiltered ? filteredPage : page;
    const newData = transformDataForGridView({
      data: tableData,
      finalColumns: finalDisplayColumns,
      columnOptions: finalDataColumns,
      additionDataFields,
      finalActions: finalActions as ViewAction[],
      recordTypesData,
      extendedSchema: finalBaseSchema,
      isAdminUser: !!currentUser?.is_admin
    });
    // Changed this to ensure finalTableData is always updated when tableData changes
    if (tableData && finalPage === 1 && finalTableData?.length <= FINAL_INIT_PAGINATION.pageSize) {
      // update rows if the currentRows and newRows are same.
      const finalTableDataRowIds = finalTableData.map((row) => row.id).sort();
      const newDataRowIds = newData.map((row) => row.id).sort();
      if (isEqual(finalTableDataRowIds, newDataRowIds)) {
        apiRef.current?.updateRows(newData);
        apiRef.current?.forceUpdate();
      } else {
        setFinalTableData(newData);
      }
      if (!pagesAdded.includes(1)) {
        setPagesAdded([1]);
      }
    }
  }, [
    tableData,
    finalTableData,
    page,
    finalDisplayColumns,
    finalColumnOptions,
    finalDataColumns,
    additionDataFields,
    finalActions,
    apiRef,
    inlineRows,
    filteredPage,
    isPageActiveFiltered,
    pagesAdded,
    recordTypesData,
    finalBaseSchema,
    currentUser?.is_admin,
    FINAL_INIT_PAGINATION.pageSize,
    currentPaginationPage,
    isSearchActive
  ]);

  useEffect(() => {
    if (aggrLoading || !aggrTableData || !aggregateFunctionColumns?.length) {
      return;
    }

    setFinalAggregateData((prev) => {
      return {
        ...prev,
        ...aggrTableData
      };
    });
  }, [aggrTableData, aggrLoading, aggregateFunctionColumns]);

  // This useEffect concats following pages data to the current page, after page 1
  useEffect(() => {
    const finalPage = isSearchActive ? currentPaginationPage || 1 : isPageActiveFiltered ? filteredPage : page;
    if (
      !tableData?.length ||
      isLoading ||
      (isPageActiveFiltered && isFilterPageLoading) ||
      !finalPage ||
      pagesAdded.includes(finalPage) ||
      finalPage === 1
    ) {
      return;
    }
    const existingRowIds = finalTableData.map((row) => row.id);
    const finalTableDataToAdd = tableData.filter((row) => !existingRowIds.includes(row.id));
    if (finalTableDataToAdd?.length) {
      setFinalTableData((prev) =>
        prev.concat(
          transformDataForGridView({
            data: tableData,
            finalColumns: finalDisplayColumns,
            columnOptions: finalColumnOptions,
            additionDataFields,
            finalActions: finalActions as ViewAction[],
            recordTypesData,
            extendedSchema: finalBaseSchema,
            isAdminUser: !!currentUser?.is_admin
          })
        )
      );
      setPagesAdded((prev) => [...prev, finalPage]);
    }
  }, [
    tableData,
    page,
    pagesAdded,
    finalDisplayColumns,
    finalColumnOptions,
    additionDataFields,
    finalActions,
    isPageActiveFiltered,
    filteredPage,
    isFilterPageLoading,
    isLoading,
    finalTableData,
    recordTypesData,
    finalBaseSchema,
    currentUser?.is_admin,
    currentPaginationPage,
    isSearchActive
  ]);

  useEffect(() => {
    const finalPage = isSearchActive ? currentPaginationPage || 1 : isPageActiveFiltered ? filteredPage : page;
    if (
      !tableData?.length ||
      currentPaginationPage !== 1 ||
      !tableData?.length ||
      finalPage !== 1 ||
      !pagesAdded.includes(1)
    ) {
      return;
    }
    let shouldReset = false;
    if (tableData.length !== finalTableData.length) {
      shouldReset = true;
    } else if (!DB_VIEWS.includes(tableName)) {
      // Check if ids don't match
      const newTableDataIds = tableData.map((row) => row.id).sort();
      const currentTableDataIds = finalTableData.map((row) => row.id).sort();
      if (!isEqual(newTableDataIds, currentTableDataIds)) {
        shouldReset = true;
      }
    }

    if (shouldReset) {
      // Only scroll if table has scrolled
      if (!inlineCreationEnabled && !!finalTableData.length && apiRef?.current) {
        const scrollPosition = apiRef.current.getScrollPosition();
        if (scrollPosition.top !== 0) {
          apiRef.current.scroll({ left: 0, top: 0 });
        }
      }
      setFinalTableData(
        transformDataForGridView({
          data: tableData,
          finalColumns: finalDisplayColumns,
          columnOptions: finalColumnOptions,
          additionDataFields,
          finalActions: finalActions as ViewAction[],
          recordTypesData,
          extendedSchema: finalBaseSchema,
          isAdminUser: !!currentUser?.is_admin
        })
      );
      setPagesAdded([1]);
    } else {
      // Update using apiRef
      const updatedData = transformDataForGridView({
        data: tableData,
        finalColumns: finalDisplayColumns,
        columnOptions: finalColumnOptions,
        additionDataFields,
        finalActions: finalActions as ViewAction[],
        recordTypesData,
        extendedSchema: finalBaseSchema,
        isAdminUser: !!currentUser?.is_admin
      });

      apiRef?.current?.updateRows?.(updatedData);
    }
  }, [
    finalFilters,
    finalTableData,
    currentSlug,
    tableData,
    page,
    pagesAdded,
    apiRef,
    finalDisplayColumns,
    finalColumnOptions,
    additionDataFields,
    finalActions,
    filteredPage,
    isPageActiveFiltered,
    recordTypesData,
    finalBaseSchema,
    currentUser?.is_admin,
    inlineCreationEnabled,
    currentPaginationPage,
    isSearchActive,
    tableName
  ]);

  useEffect(() => {
    if (!editedRecordData?.id || !apiRef?.current) return;
    // We update this row in the grid
    const formattedRow = transformDataForGridView({
      data: [editedRecordData],
      finalColumns: finalDisplayColumns,
      columnOptions: finalColumnOptions,
      recordTypesData,
      extendedSchema: finalBaseSchema
    });
    const currentRowIds = apiRef.current.getSortedRowIds();

    // This is a new row added from inline creation beyond page 1
    // We need to update the rows prop to force grid to re-render
    if (!currentRowIds.includes(editedRecordData.id)) {
      setNewRowsToAddToTable((prev) => [...(prev || []), ...formattedRow]);
    } else {
      apiRef.current?.updateRows(formattedRow);
    }
    setEditedRecordId(undefined);
    // This is needed to ensure the query doesn't return cached data if record is updated again
    queryClient.removeQueries({ queryKey: ["record", "", editedRecordData.id] });
  }, [
    editedRecordData,
    apiRef,
    finalBaseSchema,
    finalColumnOptions,
    finalDisplayColumns,
    recordTypesData,
    editedRecordId,
    queryClient
  ]);

  useEffect(() => {
    if (!formWebhookNotificationIdRef?.current || !formWebhookAddedRecordId) {
      return;
    }

    setEditedRecordId(formWebhookAddedRecordId);
  }, [formWebhookAddedRecordId]);

  useEffect(() => {
    if (finalDisplayColumns.length && !initColPinned.current && !groupByColumn && !pageConfigLoading) {
      initColPinned.current = true;

      const left = multiSelectEnabled
        ? [GRID_REORDER_COL_DEF.field, GRID_CHECKBOX_SELECTION_COL_DEF.field, GRID_EXPAND_COL_DEF].concat(
            isMobile ? [] : firstCol
          )
        : [GRID_REORDER_COL_DEF.field, GRID_EXPAND_COL_DEF].concat(isMobile ? [] : firstCol);

      setPinnedColumns((prevPinnedColumns) => {
        const prevLeft = prevPinnedColumns.left?.filter((colId) => !left.includes(colId)) || [];

        return {
          ...prevPinnedColumns,
          left: [...left, ...prevLeft]
        };
      });
    }
  }, [multiSelectEnabled, finalDisplayColumns, groupByColumn, firstCol, isMobile, pageConfigLoading]);

  useEffect(() => {
    const pinnedActions = config?.additionalConfig?.pinActionsToRight ? ["actions"] : [];
    setPinnedColumns((prevPinnedColumns) => ({
      ...prevPinnedColumns,
      left: Array.from(new Set([...(prevPinnedColumns.left || []), ...pinnedColsByDefault.left])),
      right: Array.from(new Set([...pinnedColsByDefault.right, ...pinnedActions]))
    }));
  }, [config?.additionalConfig?.pinActionsToRight, pinnedColsByDefault]);

  useEffect(() => {
    if (apiRef?.current) {
      if (typeof apiRef.current.subscribeEvent === "function") {
        apiRef.current.subscribeEvent("columnHeaderDragStart", () => {
          setDraggingColumn((prev) => (prev ? { isDragEnd: true, params: prev.params } : undefined));
        });

        apiRef.current.subscribeEvent("columnHeaderDragEnd", () => {
          setDraggingColumn((prev) => (prev ? { isDragEnd: true, params: prev.params } : undefined));
        });

        apiRef.current.subscribeEvent("rowExpansionChange", (params: GridGroupNode) => {
          setGroupExpandedState((prev) => ({
            ...prev,
            [params.id]: !!params.childrenExpanded
          }));
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef?.current]);

  const getSortedRowIndex = useCallback(
    (rowId: string) => {
      const sortedIds = apiRef.current.getSortedRowIds();
      const rowIndex = sortedIds.indexOf(rowId);

      return rowIndex;
    },
    [apiRef]
  );

  useEffect(() => {
    if (inSidebar) return;

    if (apiRef?.current) {
      // Clean row selected before to avoid issues with row selection
      gridApiRef.current?.selectRow(null);
      setGridApiRef?.({
        current: {
          selectRow: (rowId) => {
            if (rowId) {
              setHighlightedRowId(rowId);
              const rowIndex = getSortedRowIndex(rowId);

              apiRef.current.scrollToIndexes?.({ rowIndex });
            } else {
              if (highlightedRowId && apiRef.current) {
                const rowIndex = getSortedRowIndex(highlightedRowId);
                apiRef.current.setCellFocus(highlightedRowId, firstCol);
                apiRef.current.scrollToIndexes?.({ rowIndex });
                setHighlightedRowId(null);
              }
            }
          },
          gridRef: apiRef.current
        }
      });
    }

    return () => {
      setGridApiRef?.({ current: null });
    };
  }, [
    apiRef,
    gridApiRef,
    setGridApiRef,
    finalDisplayColumns,
    highlightedRowId,
    rowGroupingModel.length,
    firstCol,
    inSidebar,
    currentTablePage?.id,
    getSortedRowIndex
  ]);

  // Triggered when user clears selection from TableHeader
  useEffect(() => {
    if (
      !bulkActionsState?.selectedRows?.length &&
      rowSelectionModel?.length &&
      bulkActionsState?.isSelected &&
      !bulkActionsState?.uploadedFilesSelection?.length
    ) {
      setRowSelectionModel([]);
      updateBulkActionsStateByTableSlug(
        {
          isSelected: false,
          selectedRows: []
        },
        currentSlug
      );
    }
  }, [bulkActionsState, rowSelectionModel, updateBulkActionsStateByTableSlug, currentSlug]);

  useEffect(() => {
    if (!rowSelectionModel?.length) {
      if (bulkActionsState?.isSelected && !bulkActionsState?.uploadedFilesSelection?.length) {
        updateBulkActionsStateByTableSlug(
          {
            isSelected: false,
            selectedRows: []
          },
          currentSlug
        );
      }
      return;
    }
    const selectedRows: any[] = rowSelectionModel
      .filter((rowId) => !rowId.toString().startsWith(GRID_SELECT_COL_DEF_INITIAL))
      .map((id) => finalTableData?.find((row) => row.id === id))
      .filter(Boolean);

    if (selectedRows?.length) {
      updateBulkActionsStateByTableSlug(
        {
          isSelected: true,
          selectedRows: selectedRows,
          tablePageSlug: currentSlug,
          tableName
        },
        currentSlug
      );
    }

    return () => {
      updateBulkActionsStateByTableSlug(
        {
          isSelected: false,
          selectedRows: []
        },
        currentSlug
      );
    };
  }, [
    rowSelectionModel,
    finalTableData,
    currentSlug,
    updateBulkActionsStateByTableSlug,
    bulkActionsState?.isSelected,
    tableName,
    bulkActionsState?.uploadedFilesSelection?.length
  ]);

  // triggers when file is uploaded and selected from FileUploadWindow
  useEffect(() => {
    if (!bulkActionsState?.uploadedFilesSelection?.length) return;
    const selectedRowsId = bulkActionsState.uploadedFilesSelection.map((file) => file.id);
    const selectedRows = finalTableData?.filter((row) => selectedRowsId.includes(row.id));
    setRowSelectionModel(selectedRowsId);

    updateBulkActionsStateByTableSlug(
      {
        selectedRows: selectedRows,
        uploadedFilesSelection: []
      },
      currentSlug
    );
  }, [bulkActionsState?.uploadedFilesSelection, currentSlug, updateBulkActionsStateByTableSlug, finalTableData]);

  // It happens only once time to update group by info
  // to know if show expand/collapse button
  useEffect(() => {
    if (groupByColumn) {
      const groupByInfo: GroupByInfo = {
        enabled: true,
        isCollapsed: !!config?.additionalConfig?.enableCollapsedGroupBy,
        groupedColumns: [groupByColumn.id]
      };
      updateGroupByInfoByTableSlug(groupByInfo, currentSlug, ViewOption.GRID);
    }
  }, [config?.additionalConfig?.enableCollapsedGroupBy, groupByColumn, currentSlug, updateGroupByInfoByTableSlug]);

  const onGroupRowClick = useCallback<GridEventListener<"rowClick">>(
    (params) => {
      const rowNode = apiRef?.current?.getRowNode?.(params.id);
      if (rowNode && rowNode.type === "group") {
        apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded);
      }
    },
    [apiRef]
  );

  const onColumnConfigUpdated = useCallback(() => {
    // Refetch page data for updated column config
    queryClient.invalidateQueries({ queryKey: ["page", currentSlug] });
  }, [queryClient, currentSlug]);

  const onAdminColumnEdit = useCallback(
    (column: GridColDef) => {
      let selectedColumn;
      if (column.field.startsWith(GRID_GROUP_BY_COL_DEF)) {
        const colId = getGroupByColumnIdFromField(column.field);
        selectedColumn = finalDisplayColumns.find((col) => col.id === colId);
      } else {
        selectedColumn = finalDisplayColumns.find((col) => col.id === column.field);
      }
      if (!selectedColumn) return;

      updateSidebarState(
        {
          isOpen: true,
          sidebarType: SIDEBAR_TYPE.ADMIN_COLUMN_EDIT,
          onActionComplete: onColumnConfigUpdated,
          additionalProps: {
            column: selectedColumn,
            allColumns: finalColumnOptions,
            pageSlug: currentSlug,
            parentTableName: tableName,
            displayColumns: finalDisplayColumns,
            currentView: ViewOption.GRID
          }
        },
        STATIC_SIDEBAR_IDS.COLUMN_CONFIG_EDIT_SIDEBAR
      );
    },
    [finalDisplayColumns, updateSidebarState, finalColumnOptions, onColumnConfigUpdated, currentSlug, tableName]
  );

  const onAddFilter = useCallback(
    (col: GridColDef) => {
      const column = finalColumnOptions?.find((item) => item.id == col.field);
      const columnFilterExist = !!filtersByTableSlug?.[currentSlug]?.find((item) => item.column?.id === column?.id);

      updateOpenFiltersByTableSlug(true, currentSlug);

      if (!column || columnFilterExist) return;

      const selectOptions = getValidFilterTypes(column);
      const operator = selectOptions?.length ? selectOptions[0].value : "";
      let isFilterColumnJoinTable = false;
      let joinTableFilterColumn = "";
      let isTagsJoinTable = false;
      let useFilterLookupColumn = false;
      let isFileTagNestedColumn = false;
      let useColumnLabel = false;
      if (column?.isLookup) {
        const tableName = column?.lookupPath?.[0]?.lookupTableName;
        if (tableName && finalBaseSchema?.[tableName]?.compositePk?.length) {
          isFilterColumnJoinTable = true;
        }
        isTagsJoinTable = isColumnTagsJoinTable(column, finalBaseSchema);
        // ##HARDCODED Special case for file_tags table
        if (isColumnFileTag(column)) {
          joinTableFilterColumn = "tag_id";
        }
        // Check if column type is in FILTER_BY_COLUMN_TYPES if so we should compare lookup column instead of id
        // This may be expanded to include other conditions in the future
        if (FILTER_BY_COLUMN_TYPES.includes(column?.type)) {
          useFilterLookupColumn = true;
        }
        isFileTagNestedColumn = isColumnNestedFileTag(column);
        if (isFileTagNestedColumn) {
          useColumnLabel = true;
        }
      }

      const filters = filtersByTableSlug?.[currentSlug] || [];
      updateFiltersByTableSlug(
        [
          ...filters,
          {
            ...column,
            column: { ...column },
            filterField: column.name,
            filterOperator: operator,
            isFilterColumnJoinTable,
            joinTableFilterColumn,
            isTagsJoinTable,
            useFilterLookupColumn,
            innerAtAllLevels: !!isFileTagNestedColumn || column.type === CellType.PEOPLE || isTagsJoinTable,
            useColumnLabel: useColumnLabel || column.type === CellType.PEOPLE || isTagsJoinTable
          }
        ],
        currentSlug
      );

      // Change the open state after opening the filter
      setTimeout(() => {
        updateOpenFiltersByTableSlug(false, currentSlug);
      }, 100);
    },
    [
      finalColumnOptions,
      filtersByTableSlug,
      currentSlug,
      updateOpenFiltersByTableSlug,
      updateFiltersByTableSlug,
      finalBaseSchema
    ]
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    rowGroupingModel
  });

  const rowHeight = rowDensityByTableSlug?.[currentSlug]?.value
    ? rowDensityByTableSlug[currentSlug].value
    : config?.additionalConfig?.defaultSizing
      ? ROW_DENSITY_OPTIONS.find((option) => option.type === config.additionalConfig?.defaultSizing)?.value
      : DEFAULT_ROW_DENSITY.value;

  useEffect(() => {
    if (config?.additionalConfig?.enableAutoHeight) {
      updateRowDensityByTableSlug(
        {
          type: DensityType.AUTO,
          value: 0,
          label: "Auto"
        },
        currentSlug
      );
    }

    if (config?.additionalConfig?.defaultSizing) {
      const rowDensityDefault = ROW_DENSITY_OPTIONS.find(
        (option) => option.type === config.additionalConfig?.defaultSizing
      );

      if (!rowDensityDefault) return;

      updateRowDensityByTableSlug(rowDensityDefault, currentSlug);
    }
  }, [
    config?.additionalConfig?.defaultSizing,
    config?.additionalConfig?.enableAutoHeight,
    currentSlug,
    updateRowDensityByTableSlug
  ]);

  const shouldAddPaddingScrollBar =
    finalTableData?.length && rowHeight && parentHeight && rowHeight * finalTableData?.length + 66 > parentHeight;

  const emptyPageConfig = pageData?.page_config?.emptyPageConfig;
  const renderNoDisplayRow = useCallback(() => {
    if (isFetched && (!finalTableData?.length || isAlgoliaEmptySearchResults)) {
      return (
        <EmptyState
          showCustomMessage={emptyPageConfig?.showCustomMessage}
          title={emptyPageConfig?.showCustomMessage ? emptyPageConfig.title : `No ${tableTitle} found`}
          description={emptyPageConfig?.showCustomMessage ? emptyPageConfig.description : undefined}
          icon={emptyPageConfig?.showCustomMessage ? emptyPageConfig.icon : undefined}
        >
          <TableCreateButton
            title={tableTitle}
            columns={finalColumnOptions || []}
            params={{
              slug: currentSlug
            }}
            activeView={ViewOption.GRID}
            tableName={tableName}
            pageId={pageId}
            inSidebar={inSidebar}
            isFileTable={isFileTable}
            isFormEnabled={isFormEnabled}
            isNoteTable={false}
            tabConfig={tabConfig}
            showAddLabel={showAddLabel}
            isFormCollapsible={isFormCollapsible}
            parentRecordId={parentRecordId}
            parentRecordSlug={parentRecordSlug}
            multiFormEnabled={multiFormEnabled}
            multiFormViewIds={multiFormViewIds}
            pageViews={pageData?.views}
          />
        </EmptyState>
      );
    }
    return null;
  }, [
    tableTitle,
    finalColumnOptions,
    currentSlug,
    tableName,
    inSidebar,
    isFileTable,
    isFormEnabled,
    tabConfig,
    showAddLabel,
    finalTableData?.length,
    isAlgoliaEmptySearchResults,
    isFetched,
    parentRecordId,
    parentRecordSlug,
    isFormCollapsible,
    pageId,
    multiFormEnabled,
    multiFormViewIds,
    pageData?.views,
    emptyPageConfig
  ]);

  // row class names
  const getRowClassName = useCallback(
    (params: any) => {
      const isGroupingRow = params.id.toString().includes(GRID_SELECT_COL_DEF_INITIAL);

      if (!isGroupingRow) return "";

      return clsx(
        !!highlightedRowId && "DataGridView--row--sidebar-open",
        params.row.id === highlightedRowId && "DataGridView--row-highlight",
        isGroupingRow && "bg-gray-100 dark:bg-gray-900 cursor-pointer",
        isGroupingRow && config?.additionalConfig?.hideGroupLines && "grouping-row--hide-lines"
      );
    },
    [highlightedRowId, config?.additionalConfig?.hideGroupLines]
  );

  const getCellClassName = useCallback((params: any) => {
    if (params.rowNode.type === "group") return "!outline-none";
    return "";
  }, []);

  const onRowSelectionModelChange = useCallback(
    (newRowSelectionModel: GridRowSelectionModel) => {
      const selectedRowsMap = apiRef?.current?.getSelectedRows();
      const selectedRowsKeys = Array.from(selectedRowsMap.keys());
      const removedRows = getRemovedRows(selectedRowsKeys, newRowSelectionModel, apiRef.current);
      const selectedRows = getSelectedRows(difference(newRowSelectionModel, removedRows), apiRef.current);

      const finalSelectedRows = selectedRows?.filter((id) => !id?.toString()?.startsWith?.(GRID_GROUP_PARENT_ROOT));

      setRowSelectionModel(finalSelectedRows);
    },
    [apiRef]
  );

  const getRowHeight = useCallback(
    (params: any) => {
      if (config?.additionalConfig?.enableAutoHeight) {
        return "auto";
      }

      if (typeof params.id === "string" && params.id.startsWith(GRID_SELECT_COL_DEF_INITIAL)) {
        return 70;
      }
      return rowHeight;
    },
    [rowHeight, config?.additionalConfig?.enableAutoHeight]
  );

  const onColumnOrderChange = useCallback((params: any) => {
    setDraggingColumn({ params });
  }, []);

  const onFilterModelChange = useCallback((model: any) => setMuiFilterModel(model as any), []);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (sortingByTableSlug?.[currentSlug]?.length || isSearchResultShown) {
        return;
      }
      const addRowAnchor = event.currentTarget.querySelector("#add-row-anchor");
      const rowId = event.currentTarget.parentElement?.dataset.id;

      if (rowId === NEW_INLINE_ROW_ID) return;
      if (addRowAnchor !== null) {
        setAnchorEl(addRowAnchor as HTMLElement);
        setAnchorRowId(rowId);
      } else {
        handleInlineRowReset();
      }
    },
    [handleInlineRowReset, sortingByTableSlug, currentSlug, isSearchResultShown]
  );

  const allSlotProps = useMemo(() => {
    return {
      columnMenu: {
        currentUser,
        onAdminColumnEdit,
        onAddFilter,
        columnOptions: finalColumnOptions,
        onColumnWebhookActionClick: handleColumnWebhookActionClick
      },
      cell: {
        tabIndex: 1,
        onMouseEnter:
          inlineCreationEnabled && config?.additionalConfig?.showInlineFloatingButton ? handlePopoverOpen : undefined
      },
      row: {
        conditionalFormatting,
        columnOptions: finalColumnOptions
      }
    };
  }, [
    currentUser,
    onAdminColumnEdit,
    onAddFilter,
    finalColumnOptions,
    inlineCreationEnabled,
    handlePopoverOpen,
    config?.additionalConfig?.showInlineFloatingButton,
    handleColumnWebhookActionClick,
    conditionalFormatting
  ]);

  const slotsValues = useMemo(() => {
    return {
      columnMenu: CustomColumnMenu,
      groupingCriteriaCollapseIcon: GroupingCollapseIcon,
      groupingCriteriaExpandIcon: GroupingExpandIcon,
      noRowsOverlay: renderNoDisplayRow,
      loadingOverlay: DataLoader,
      row: MemoizedRow,
      columnHeaders: MemoizedColumnHeaders
    };
  }, [renderNoDisplayRow]);

  const groupingColDepProps = useCallback(
    (params: GridGroupingColDefOverrideParams) => {
      if (params.fields.includes(rowGroupingModel[0]) && rowGroupingModel.length > 1) {
        return {
          hideDescendantCount: true,
          mainGroupingCriteria: rowGroupingModel[0]
        };
      } else {
        return {
          hideDescendantCount: true
        };
      }
    },
    [rowGroupingModel]
  );

  const handleRowUpdate = useCallback(
    (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
      return new Promise(async (resolve) => {
        if (newRow.id === NEW_INLINE_ROW_ID) {
          const createdRow = await handleRowCreation(newRow);
          if (createdRow) {
            refetch?.();
            setInlineRows((prev) => [...(prev || []), { ...newRow, id: createdRow.id }]);
            return resolve({ ...newRow, ...inlineRows, id: createdRow.id, originalRow: createdRow });
          } else {
            handleInlineRowReset();
            setAnchorRowId(undefined);
            setInlineRows(undefined);
            return resolve(null);
          }
        }

        if (newRow.id !== NEW_INLINE_ROW_ID) {
          // We need to update any number columns and call record edit if changed
          // others are handled by renderEditCell
          let changedColumn: string | undefined = undefined;
          Object.keys(newRow).forEach((key) => {
            if ((typeof newRow[key] === "number" || newRow[key] === null) && newRow[key] !== oldRow?.[key]) {
              changedColumn = key;
            }
          });
          const col = changedColumn ? finalDisplayColumns.find((col) => col.id === changedColumn) : undefined;
          const colType = col ? getType(col) : undefined;
          if (changedColumn && col && newRow.originalRow && colType === "number") {
            handleRecordEdit(newRow[changedColumn], oldRow, col);
          }
        }
        if (newRow.originalRow) {
          const transformedRows = transformDataForGridView({
            data: [newRow.originalRow],
            finalColumns: finalDisplayColumns,
            columnOptions: finalColumnOptions,
            additionDataFields,
            finalActions: finalActions as ViewAction[],
            recordTypesData,
            extendedSchema: finalBaseSchema,
            isAdminUser: !!currentUser?.is_admin
          });
          return resolve(transformedRows[0]);
        }

        return resolve(newRow);
      });
    },
    [
      refetch,
      additionDataFields,
      finalColumnOptions,
      finalActions,
      finalDisplayColumns,
      handleRecordEdit,
      handleRowCreation,
      handleInlineRowReset,
      inlineRows,
      recordTypesData,
      finalBaseSchema,
      currentUser?.is_admin
    ]
  );

  const handleRowOrderChange = useCallback(
    async (rowToUpdate: RecordItem) => {
      if (!config?.additionalConfig?.sortDragAndDrop?.columnToUpdate) {
        return;
      }

      try {
        const newRank = LexoRank.getNewRank(
          finalTableRowsToRender || [],
          rowToUpdate.oldIndex,
          rowToUpdate.targetIndex,
          "sortOrder"
        );

        if (!newRank) {
          return;
        }

        const input = {
          [config?.additionalConfig?.sortDragAndDrop?.columnToUpdate]: newRank.toString()
        };
        await onFormSubmitWithOptions(input, {
          record: rowToUpdate.row
        });
      } catch (err: any) {
        logError({
          error: err as Error,
          message: err.message || "Error saving record in DataGrid",
          source: "DataGrid - handleRowOrderChange",
          type: ERROR_TYPES.STAFF_TABLE,
          url: window.location.href,
          additionalInfo: {
            rowToUpdate
          }
        });
        toast.error("Error saving record");
      }
    },
    [
      config?.additionalConfig?.sortDragAndDrop?.columnToUpdate,
      finalTableRowsToRender,
      onFormSubmitWithOptions,
      logError
    ]
  );

  const handleCellEditStop = useCallback((params: GridCellEditStopParams, event: MuiEvent<MuiBaseEvent>) => {
    setCellIsEditing(false);
    if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
      return;
    }

    if (isKeyboardEvent(event) && event.shiftKey) {
      event.defaultMuiPrevented = true;
    }
  }, []);

  const finalSX = useMemo(() => {
    return !finalTableData || finalTableData?.length === 0
      ? {
          "& .MuiDataGrid-pinnedColumns": {
            borderColor: "transparent !important",
            borderWidth: "0 !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            borderColor: "transparent !important",
            display: "none"
          },
          "& .MuiDataGrid-pinnedRows": {
            display: "none"
          },
          "& .MuiDataGrid-virtualScrollerContent": {
            display: "none"
          },
          "& .MuiDataGrid-footerContainer": { display: "none" }
        }
      : {
          "& .MuiDataGrid-pinnedColumns--left": {
            minHeight: "auto !important"
          },
          "& .MuiDataGrid-pinnedColumns--right": {
            minHeight: "auto !important"
          },
          "& .MuiDataGrid-cell--editing": {
            backgroundColor: "transparent !important",
            position: "relative"
          },
          "& .MuiDataGrid-pinnedColumnHeaders--right": {
            paddingRight: `${shouldAddPaddingScrollBar && hasScrollbar ? 15 : 0}px !important`
          },
          "& .MuiDataGrid-row--dragging": {
            backgroundColor: "#efefef !important"
          },
          "& .MuiDataGrid-rowReorderCell--draggable": {
            height: "100% !important",
            width: "100% !important"
          },
          ...(!config?.additionalConfig?.isPaginatedView
            ? { ".MuiDataGrid-footerContainer": { display: "none" } }
            : {}),
          ...(allowRowPinning && pinnedRowsIds?.top?.length
            ? { ".MuiDataGrid-virtualScroller": { display: "flex", flexDirection: "column" } }
            : {})
        };
  }, [finalTableData, config, shouldAddPaddingScrollBar, hasScrollbar, pinnedRowsIds.top.length, allowRowPinning]);

  const onPaginationModelChange = useCallback(
    (updatedPaginationModel: GridPaginationModel) => {
      if (!totalCount) return;
      updateGridPaginationByTableSlug(
        {
          currentPage: updatedPaginationModel.page
        },
        finalSlug
      );
      const updatedPageIndex = updatedPaginationModel.page * INITIAL_GRID_PAGINATION_MODEL.pageSize;
      if (finalTableData?.length <= updatedPageIndex && updatedPageIndex <= totalCount) {
        // Fetch more rows
        updatePaginationByTableSlug(
          {
            ...paginationByTableSlug?.[finalSlug],
            currentPage: (paginationByTableSlug?.[finalSlug].currentPage || 1) + 1
          },
          finalSlug
        );
      }
      setPaginationModel(updatedPaginationModel);
    },
    [
      finalTableData,
      totalCount,
      paginationByTableSlug,
      finalSlug,
      updatePaginationByTableSlug,
      updateGridPaginationByTableSlug
    ]
  );

  // If table is paginated, we set all required pagination props here
  const paginationModelProps = useMemo(() => {
    if (!config?.additionalConfig?.isPaginatedView) {
      return {
        disableVirtualization: finalTableData?.length > 0 && finalTableData?.length < 200,
        onRowsScrollEnd: handleFetchRows,
        rowBuffer: 50,
        hideFooterPagination: true,
        keepNonExistentRowsSelected: true
      };
    }
    // If infinite scroll pass handleFetchRows
    return {
      paginationMode: "server",
      rowCount: finalTotalCount || 0,
      onPaginationModelChange,
      paginationModel,
      pageSizeOptions: [10, 50, 100],
      pagination: true,
      keepNonExistentRowsSelected: true
    };
  }, [
    finalTotalCount,
    onPaginationModelChange,
    paginationModel,
    config?.additionalConfig?.isPaginatedView,
    handleFetchRows,
    finalTableData?.length
  ]);

  // This hook resets pagination when search is cleared etc.
  useEffect(() => {
    if (
      !config?.additionalConfig?.isPaginatedView ||
      gridPaginationByTableSlug?.[finalSlug]?.currentPage !== 0 ||
      (gridPaginationByTableSlug?.[finalSlug]?.currentPage === 0 &&
        gridPaginationByTableSlug?.[finalSlug]?.currentPage === paginationModel.page)
    ) {
      return;
    }
    setPaginationModel((prev) => ({
      ...prev,
      page: gridPaginationByTableSlug?.[finalSlug]?.currentPage || 0
    }));
  }, [gridPaginationByTableSlug, finalSlug, paginationModel.page, config?.additionalConfig?.isPaginatedView]);

  useEffect(() => {
    if (!finalTableData?.length || !config?.additionalConfig?.isPaginatedView) return;

    let finalDataToUse = finalTableData;
    if ((inlineCreationEnabled && !sortingByTableSlug?.[currentSlug]?.length) || enableSortDragAndDrop) {
      finalDataToUse = finalTableRowsToRender || [];
    }

    const finalDataForGrid = finalDataToUse.slice(
      paginationModel.page * INITIAL_GRID_PAGINATION_MODEL.pageSize,
      (paginationModel.page + 1) * INITIAL_GRID_PAGINATION_MODEL.pageSize
    );
    const finalTableDataRowIds = finalPaginatedTableData?.map((row) => row.id).sort();
    const newDataRowIds = finalDataForGrid.map((row) => row.id).sort();
    if (isEqual(finalTableDataRowIds, newDataRowIds)) {
      apiRef.current?.updateRows(finalDataForGrid);
      apiRef.current?.forceUpdate();
    } else {
      setFinalPaginatedTableData(finalDataForGrid);
    }
  }, [
    finalTableData,
    paginationModel,
    config,
    finalTableRowsToRender,
    inlineCreationEnabled,
    sortingByTableSlug,
    currentSlug,
    enableSortDragAndDrop,
    isSearchResultShown,
    finalPaginatedTableData,
    apiRef
  ]);

  useEffect(() => {
    if (!rowPinningColumn || !finalGridRows?.length) return;

    const rowsToPinIds: string[] = finalGridRows
      .filter((row) => {
        const isPinned = getColumnValueFromRowData({
          row: row.originalRow,
          col: rowPinningColumn,
          colOptions: finalColumnOptions || [],
          recordTypesData,
          extendedSchema: finalBaseSchema
        });
        return !!isPinned;
      })
      .map((row) => row.id);

    setPinnedRowsIds({
      top: rowsToPinIds
    });
  }, [finalColumnOptions, recordTypesData, finalBaseSchema, rowPinningColumn, finalGridRows]);

  const { finalGridData, finalPinnedRowsData } = useMemo(() => {
    if (!isMounted || !finalGridRows) return { finalGridData: [], finalPinnedRowsData: { top: [] } };
    const rowsData: GridRowModel[] = [];
    const pinnedRowsData: { top: GridRowModel[] } = {
      top: []
    };

    finalGridRows?.forEach((row) => {
      if (allowRowPinning && pinnedRowsIds.top.includes(row.id)) {
        pinnedRowsData.top.push(row);
      } else {
        rowsData.push(row);
      }
    });

    return {
      finalGridData: rowsData,
      finalPinnedRowsData: pinnedRowsData
    };
  }, [finalGridRows, pinnedRowsIds, allowRowPinning, isMounted]);

  const handleEnter = useCallback(
    (row: RecordItem) => {
      if (rowSelectionModel?.length) {
        setRowSelectionModel((prev) => {
          if (prev.includes(row.id)) {
            return prev.filter((id) => id !== row.id);
          }
          return [...prev, row.id];
        });
      } else {
        handleRowClick?.(row);
      }
    },
    [handleRowClick, rowSelectionModel?.length]
  );

  const checkIfCellIsEditable = useCallback(
    (params: GridCellParams) => {
      const isEditable = !isParentRecordLocked && !params.row?.is_locked && !params.row?.originalRow?.is_locked;
      return isEditable;
    },
    [isParentRecordLocked]
  );

  useEffect(() => {
    if (!apiRef?.current) return;
    const sortedIds = apiRef.current.getSortedRowIds() || [];
    const sortedRows = sortedIds
      .map((id) => finalGridRows?.find((row) => row.id === id))
      .filter(Boolean) as RecordItem[];

    setSortedRows(sortedRows);
  }, [apiRef, finalGridRows, groupByInfoByTableSlug, currentSlug]);

  useEffect(() => {
    if (
      !apiRef?.current ||
      !finalTableData?.length ||
      setInlineEditCellRef?.current ||
      scrollToNewInlineRowRef.current
    ) {
      return;
    }

    // @ts-ignore
    const groups = apiRef.current.getRowNode?.(GRID_ROOT_GROUP_ID)?.children;
    if (groups?.length) {
      // Only reset if collapsed state has changed
      const isAllCollapseStateSame =
        lastGroupCollapsedStateUpdatedRef.current !== undefined &&
        groupByInfoByTableSlug?.[currentSlug]?.[ViewOption.GRID]?.isCollapsed ===
          lastGroupCollapsedStateUpdatedRef.current;
      setGroupExpandedState((prev) => {
        const updatedState = groups.reduce((acc: RecordItem, groupId: string) => {
          acc[groupId] =
            isAllCollapseStateSame && prev?.[groupId] !== undefined
              ? prev[groupId]
              : !groupByInfoByTableSlug?.[currentSlug]?.[ViewOption.GRID]?.isCollapsed;
          return acc;
        }, {});

        return updatedState;
      });
      lastGroupCollapsedStateUpdatedRef.current = groupByInfoByTableSlug?.[currentSlug]?.[ViewOption.GRID]?.isCollapsed;
    }
  }, [apiRef, groupByInfoByTableSlug, currentSlug, finalTableData]);

  // This useEffect calls handleFetchRows till all data is loaded when groups are collapsed by default
  // MUI grid has a bug where it does not call fetch more if page scrollbar is not present
  // https://github.com/mui/mui-x/issues/4371
  useEffect(() => {
    if (
      !groupByInfoByTableSlug?.[currentSlug]?.[ViewOption.GRID]?.enabled ||
      !config?.additionalConfig?.enableCollapsedGroupBy ||
      (paginationByTableSlug?.[currentSlug]?.currentPage || 0) < 1 ||
      (totalCount || 0) < 200 ||
      finalTableData?.length < 400 || // Only fetch after 600 rows as grid stops fetching after this
      finalTableData?.length === totalCount ||
      lastGroupCollapsedStateUpdatedRef.current !==
        groupByInfoByTableSlug?.[currentSlug]?.[ViewOption.GRID]?.isCollapsed ||
      isLoading
    ) {
      return;
    }

    handleFetchRows({});
  }, [
    groupByInfoByTableSlug,
    currentSlug,
    finalTableData,
    handleFetchRows,
    paginationByTableSlug,
    totalCount,
    isLoading,
    config?.additionalConfig?.enableCollapsedGroupBy
  ]);

  useEffect(() => {
    if (!setEditCellRef?.current || !apiRef?.current || !compositeKeysColumn) return;
    const editCol = apiRef.current
      .getVisibleColumns()
      .find((col) => (compositeKeysColumn as TableColumnType).id === col.field);
    if (!editCol) return;
    const editRow = finalTableRowsToRender?.find((row) => row.id === setEditCellRef.current);
    if (editRow) {
      const field = editCol.field;

      const cellMode = apiRef.current.getCellMode(editRow.id, field);
      if (cellMode === "view") {
        apiRef.current.startCellEditMode({ id: editRow.id, field });
        setEditCellRef.current = null;
      }
    }
  }, [finalTableRowsToRender, setEditCellRef, finalDisplayColumns, apiRef, compositeKeysColumn]);

  useEffect(() => {
    if (
      !scrollToNewInlineRowRef?.current ||
      !finalTableRowsToRender?.length ||
      !finalDisplayColumns?.length ||
      !apiRef?.current
    )
      return;

    const rowIndex = finalTableRowsToRender.findIndex((row) => row.id === scrollToNewInlineRowRef.current);
    if (rowIndex === -1) return;
    const field = finalDisplayColumns?.length >= 2 ? finalDisplayColumns[2]?.id : finalDisplayColumns[0]?.id;
    apiRef.current.startCellEditMode({ id: scrollToNewInlineRowRef.current, field });
    scrollToNewInlineRowRef.current = null;
  }, [finalTableRowsToRender, apiRef, scrollToNewInlineRowRef, finalDisplayColumns]);

  useEffect(() => {
    if (!setInlineEditCellRef?.current || !apiRef?.current) return;
    const editCol = apiRef.current.getVisibleColumns().find((col) => col.editable);
    if (!editCol) return;
    let editRow = finalTableRowsToRender?.find((row) => row.id === setInlineEditCellRef.current);
    const rowIds = apiRef.current.getAllRowIds();
    if (!editRow && rowIds?.includes(setInlineEditCellRef.current)) {
      // Row has been added through updateRows to apiRef
      editRow = apiRef.current.getRow(setInlineEditCellRef.current) as RecordItem;
    }
    if (editRow) {
      const field = editCol.field;
      const cellMode = apiRef.current.getCellMode(editRow.id, field);
      if (cellMode === "view") {
        apiRef.current.startCellEditMode({ id: editRow.id, field });
        setInlineEditCellRef.current = null;
      }
    }
  }, [finalTableRowsToRender, setInlineEditCellRef, finalDisplayColumns, apiRef]);

  useEffect(() => {
    if (parentOffsetHeight === parentHeight) return;
    if (parentOffsetHeight) {
      setParentHeight(parentOffsetHeight);
    }
  }, [parentOffsetHeight, parentHeight]);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef?.current?.offsetHeight) {
        setParentHeight(parentRef?.current?.offsetHeight);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [parentRef]);

  useKeypressNavigationView({
    data: sortedRows,
    currentId: highlightedRowId,
    enabled: pageId === currentTablePage?.id && !cellIsEditing,
    apiRef: gridApiRef,
    onEnter: handleEnter
  });

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const hasNoCols = !!pageData?.id && (!pageData?.columns?.length || !formattedColumns?.length);

  if (error) {
    return <ErrorView error={error} />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorDisplay} onError={handleOnError}>
      <div className={clsx("!h-full", containerClassName)} ref={parentRef}>
        <StyledDataGridPro
          apiRef={apiRef}
          editMode={GridEditModes.Cell}
          rows={finalGridData}
          pinnedRows={finalPinnedRowsData}
          columns={formattedColumns}
          checkboxSelection={multiSelectEnabled}
          disableRowSelectionOnClick
          columnVisibilityModel={columnVisibilityModel}
          loading={
            ((isLoading ||
              !tableData ||
              (!!tableData.length && !finalGridData?.length && !isAlgoliaEmptySearchResults)) &&
              !hasNoCols &&
              !error &&
              !isFetched) ||
            !isMounted
          }
          showCellVerticalBorder={false}
          showColumnRightBorder={false}
          sortModel={sortModel}
          filterModel={muiFilterModel}
          sortingOrder={sortingOrder}
          onSortModelChange={onSortModelChange}
          onFilterModelChange={onFilterModelChange} // only update filters by custom component
          pinnedColumns={pinnedColumns}
          onPinnedColumnsChange={handlePinnedColumnsChange}
          columnHeaderHeight={finalTableData?.length === 0 ? 0 : 50}
          rowHeight={rowHeight}
          onColumnHeaderOver={handleInlineRowReset}
          getRowHeight={getRowHeight}
          onCellClick={handleOnCellClick}
          aggregationModel={aggregationModel}
          columnGroupingModel={columnGroupingModel}
          onAggregationModelChange={handleAggregationModelChange}
          initialState={initialState}
          rowGroupingModel={rowGroupingModel}
          rowGroupingColumnMode="multiple"
          onRowGroupingModelChange={handleGroupingModelChange}
          groupingColDef={groupingColDepProps}
          onRowClick={onGroupRowClick}
          getRowClassName={getRowClassName}
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
          // @ts-ignore
          slots={slotsValues}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          processRowUpdate={handleRowUpdate}
          // @ts-ignore
          slotProps={allSlotProps}
          onColumnOrderChange={onColumnOrderChange}
          onCellEditStop={handleCellEditStop}
          columnBuffer={formattedColumns.length}
          // @ts-ignore
          sx={finalSX}
          onColumnVisibilityModelChange={handleVisibilityChange}
          onRowOrderChange={handleRowOrderChange}
          rowReordering={
            !!config?.additionalConfig?.sortDragAndDrop?.enabled && !isSearchResultShown && !rowGroupingModel.length
          }
          getCellClassName={getCellClassName}
          isCellEditable={checkIfCellIsEditable}
          isGroupExpandedByDefault={isGroupExpandedByDefault}
          {...paginationModelProps}
        />
        {hasNoCols ? (
          <div className="absolute left-0 top-0 z-[20]  flex h-full w-full items-center justify-center ">
            <AddColumnButton
              pageId={pageData.id}
              tableName={tableName}
              tableSlug={currentSlug}
              currentView={ViewOption.GRID}
            />
          </div>
        ) : null}
        {!isParentRecordLocked && (
          <Popper
            id="floating-add-row-button-popper"
            open={!!anchorEl}
            anchorEl={anchorEl}
            className="inset-0 !z-50 m-0"
            placement="right"
          >
            <div
              className="cursor-pointer rounded-full bg-primary-800 px-3 py-1 text-xs !text-white hover:bg-primary-900 dark:bg-primary-dark-800"
              onClick={handleNewRowAdd}
            >
              New Item
            </div>
          </Popper>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default DataGridView;
