import React from "react";
import {
  GridColumnMenuContainer,
  GridColumnMenuProps,
  GridColumnMenuPinningItem,
  GRID_AGGREGATION_FUNCTIONS,
  GridColumnMenuHideItem,
  GridColumnMenuSortItem,
  GridColumnMenuGroupingItem
} from "@mui/x-data-grid-premium";
import { GridColumnMenuAggregationItem } from "@mui/x-data-grid-premium/components/GridColumnMenuAggregationItem";
import { useMediaQuery } from "usehooks-ts";
import Icon from "components/Icon";
import { ColumnWebhookAction, TableColumnType } from "types/baTypes";
import { CellType } from "utils/constants";
import { GRID_GROUP_BY_COL_DEF } from "./utils";

type BAGridColumnMenuProps = GridColumnMenuProps & {
  currentUser: any;
  onAdminColumnEdit: (colDef: any) => void;
  onAddFilter: (colDef: any) => void;
  columnOptions: TableColumnType[];
  onColumnWebhookActionClick: (column: TableColumnType, action: ColumnWebhookAction) => void;
};

const CustomColumnMenu = React.forwardRef<HTMLUListElement, BAGridColumnMenuProps>(
  (props: BAGridColumnMenuProps, ref) => {
    const { hideMenu, colDef, currentUser, onAdminColumnEdit, onAddFilter, columnOptions, onColumnWebhookActionClick } =
      props;

    const isDesktop = useMediaQuery("(min-width: 1024px)");
    const column = columnOptions.find((c) => c.id === colDef.field);

    return (
      <GridColumnMenuContainer ref={ref} {...props}>
        <GridColumnMenuSortItem onClick={hideMenu} colDef={colDef} />
        {!!column?.hasFilter && column?.type !== CellType.GENERIC_CELL && (
          <li
            className="MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters cursor-pointer border-none"
            onClick={(e) => {
              onAddFilter(colDef);
              hideMenu(e);
            }}
          >
            <Icon name="filter" className="MuiSvgIcon-root h-4 w-4 shrink-0" />
            <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary">Add Filter</span>
          </li>
        )}
        <GridColumnMenuHideItem onClick={hideMenu} colDef={colDef} />
        {!!(colDef.aggregable && !colDef.field.startsWith(GRID_GROUP_BY_COL_DEF)) && (
          <GridColumnMenuAggregationItem
            label={"Aggregation"}
            colDef={colDef}
            onClick={hideMenu}
            availableAggregationFunctions={Object.keys(GRID_AGGREGATION_FUNCTIONS)}
          />
        )}
        <GridColumnMenuGroupingItem onClick={hideMenu} colDef={colDef} />
        {isDesktop && <GridColumnMenuPinningItem onClick={hideMenu} colDef={colDef} />}
        {column?.cellConfig?.webhookActionProps?.showWebhookAction &&
        !!column.cellConfig?.webhookActionProps?.actions?.length ? (
          <>
            {column.cellConfig?.webhookActionProps?.actions?.map((action) => (
              <li
                key={action.id}
                className="MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters cursor-pointer border-none"
                onClick={(e) => {
                  onColumnWebhookActionClick(column, action);
                  hideMenu(e);
                }}
              >
                <Icon
                  name={action.icon ? (action.icon as any) : "external-link"}
                  className="MuiSvgIcon-root h-4 w-4 shrink-0"
                />
                <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary">
                  {action.label || "Send to Webhook"}
                </span>
              </li>
            ))}
          </>
        ) : null}
        {currentUser?.full_name && currentUser?.is_admin ? (
          <li
            className="MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters cursor-pointer border-none"
            onClick={(e) => {
              onAdminColumnEdit(colDef);
              hideMenu(e);
            }}
          >
            <Icon name="pen-line" className="MuiSvgIcon-root h-4 w-4 shrink-0" />
            <span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary">Edit Column</span>
          </li>
        ) : null}
      </GridColumnMenuContainer>
    );
  }
);

CustomColumnMenu.displayName = "CustomGridColumnMenu";

export default CustomColumnMenu;
