import { useCallback, useEffect, useRef, useState } from "react";
import isEqual from "lodash/isEqual";

import { RecordItem } from "types/common";
import { CellType, FILTER_OPERATOR } from "utils/constants";
import { SortItem, TableColumnType } from "types/baTypes";

import useTableActionsState from "./useTableActionsState";
import useTableData from "./useTableData";

const useViewFilter = (tableSlug: string, viewTableName?: string) => {
  const [finalViewColumns, setFinalViewColumns] = useState<TableColumnType[]>([]);
  const [viewFilters, setViewFilters] = useState<RecordItem[]>([]);
  const [viewSorting, setViewSorting] = useState<SortItem[]>([]);
  const {
    filtersByTableSlug,
    paginationByTableSlug,
    sortingByTableSlug,
    updatePaginationByTableSlug,
    superAdminOrgIdFilterDisabledByTableSlug
  } = useTableActionsState();
  const [resultIds, setResultIds] = useState<string[]>([]);
  const [allResultIds, setAllResultIds] = useState<string[]>([]);
  const [finalCount, setFinalCount] = useState<number>(0); // Count of filtered data unless NOT IN filter added
  const lastFetchedPage = useRef<number>(1);

  const {
    data: filteredData,
    totalCount: filteredCount,
    isLoading
  } = useTableData({
    tableName: viewTableName || "",
    columns: finalViewColumns,
    filters: viewFilters,
    pagination: paginationByTableSlug?.[tableSlug],
    sorting: viewSorting,
    hookOptions: {
      enabled: (!!viewFilters?.length || !!viewSorting?.length) && !!finalViewColumns?.length && !!viewTableName
    },
    disableOrgIdFilter: !!superAdminOrgIdFilterDisabledByTableSlug?.[tableSlug]
  });

  const runViewFilter = useCallback(
    (filters: RecordItem[]) => {
      const finalFilterCols = filters.map((filter) => {
        return {
          ...filter,
          filterField: filter.column.name || filter.column.header.toLowerCase().split(" ").join("_"), // View has column names by headers
          isViewFilter: true
        };
      });
      setViewFilters(finalFilterCols);
      if (!finalViewColumns?.length) {
        setFinalViewColumns([
          {
            id: "id_view",
            header: "id",
            name: "id",
            type: CellType.TEXT
          }
        ]);
      }
    },
    [finalViewColumns?.length]
  );

  useEffect(() => {
    if (!viewTableName) return;
    if (!filtersByTableSlug?.[tableSlug]?.length) {
      if (!sortingByTableSlug?.[tableSlug]?.length) {
        if (!!resultIds.length) {
          // Reset
          setAllResultIds([]);
          setResultIds([]);
          setViewFilters([]);
          setViewSorting([]);
          updatePaginationByTableSlug(
            {
              ...paginationByTableSlug?.[tableSlug],
              currentPage: 1
            },
            tableSlug
          );
          lastFetchedPage.current = 1;
        }
      }
      return;
    }
    const filters = filtersByTableSlug[tableSlug];
    const finalFilters = filters?.length
      ? filters.filter(
          (filter: RecordItem) =>
            (filter.type === CellType.BOOLEAN ||
              !!filter.filterValue ||
              [FILTER_OPERATOR.NOT_EMPTY, FILTER_OPERATOR.EMPTY].includes(filter.filterOperator)) &&
            filter.filterOperator !== FILTER_OPERATOR.OR
        )
      : [];
    if (!finalFilters.length) return;
    runViewFilter(finalFilters);
  }, [
    filtersByTableSlug,
    tableSlug,
    resultIds?.length,
    sortingByTableSlug,
    runViewFilter,
    paginationByTableSlug,
    updatePaginationByTableSlug,
    viewTableName
  ]);

  useEffect(() => {
    if (
      !paginationByTableSlug?.[tableSlug]?.currentPage ||
      paginationByTableSlug?.[tableSlug]?.currentPage === 1 ||
      paginationByTableSlug?.[tableSlug]?.currentPage <= lastFetchedPage.current ||
      !viewTableName
    ) {
      return;
    }
    if (
      allResultIds?.length &&
      !viewFilters?.find(
        (filter) => filter.filterOperator === FILTER_OPERATOR.NOT_IN && isEqual(filter.filterValue, allResultIds)
      )
    ) {
      runViewFilter([
        {
          column: {
            id: "id",
            header: "ID",
            isLookup: false,
            name: "id"
          },
          filterOperator: FILTER_OPERATOR.NOT_IN,
          filterValue: allResultIds,
          filterField: "id"
        }
      ]);
      lastFetchedPage.current = lastFetchedPage.current + 1;
    }
  }, [paginationByTableSlug, tableSlug, allResultIds, runViewFilter, viewFilters, viewTableName]);

  useEffect(() => {
    if (
      viewFilters?.find((filter) => filter.filterOperator === FILTER_OPERATOR.NOT_IN) ||
      !filteredCount ||
      filteredCount === finalCount ||
      !viewTableName
    )
      return;
    setFinalCount(filteredCount);
  }, [viewFilters, filteredCount, finalCount, viewTableName]);

  useEffect(() => {
    if (!viewTableName) return;
    if (!sortingByTableSlug?.[tableSlug]?.length) {
      if (!filtersByTableSlug?.[tableSlug]?.length) {
        if (!!resultIds.length) {
          // Reset
          setAllResultIds([]);
          setResultIds([]);
          setViewFilters([]);
          setViewSorting([]);
          updatePaginationByTableSlug(
            {
              ...paginationByTableSlug?.[tableSlug],
              currentPage: 1
            },
            tableSlug
          );
          lastFetchedPage.current = 1;
        }
      }
      return;
    }
    const sortBy = sortingByTableSlug[tableSlug];
    const finalSort = sortBy?.map((sortItem) => ({
      ...sortItem,
      isViewSort: true
    }));
    const filteredFinalSort = finalSort.filter(
      (sortItem) => !viewSorting?.find((item) => item.id === sortItem.id && item.desc === sortItem.desc)
    );
    if (!filteredFinalSort?.length) return;
    // Add filter to not include already fetched ids
    if (!finalViewColumns?.length) {
      setFinalViewColumns([
        {
          id: "id_view",
          header: "id",
          name: "id",
          type: CellType.TEXT
        }
      ]);
    }
    const existingFilterWithNewSort: string[] = [];
    const allPrevFilters = viewSorting.map((prevFilter) => {
      const filter = filteredFinalSort.find((filter) => filter.id === prevFilter.id);
      if (filter) {
        existingFilterWithNewSort.push(filter.id);
        return filter;
      }
      return prevFilter;
    });
    const finalSortList = [
      ...allPrevFilters,
      ...filteredFinalSort.filter((filter) => !existingFilterWithNewSort.includes(filter.id))
    ];

    setViewSorting(finalSortList);
  }, [
    sortingByTableSlug,
    tableSlug,
    resultIds?.length,
    finalViewColumns?.length,
    filtersByTableSlug,
    viewSorting,
    resultIds,
    runViewFilter,
    paginationByTableSlug,
    updatePaginationByTableSlug,
    viewTableName
  ]);

  useEffect(() => {
    if (!filteredData?.length || !viewTableName) return;
    const ids = filteredData.map((item) => item.id);
    setResultIds(ids);
    setAllResultIds((prev) => [...prev, ...ids]);
  }, [filteredData, viewTableName]);

  return {
    resultIds,
    totalCount: finalCount,
    currentPage: paginationByTableSlug?.[tableSlug]?.currentPage || 1,
    isLoading
  };
};

export default useViewFilter;
