import clsx from "clsx";

import CellGroup from "components/CellGroup";
import Button from "components/Button";

import { ColumnCellAdditionalValueFields, TableColumnType, TableViewConfig } from "types/baTypes";
import { GridViewColumn, RecordItem } from "types/common";

import { ButtonSize, DensityType } from "utils/constants";
import { getSizeBasedInRowDensity } from "./utils";

type GroupCellProps = {
  count: number;
  row: RecordItem;
  col: TableColumnType;
  columnOptions: GridViewColumn[];
  rowDensityType: DensityType;
  className?: string;
  handleEmailClick: (row?: RecordItem) => void;
  handleCellDetailActionClick: (record: RecordItem, tablePath: string) => void;
  config?: TableViewConfig;
  additionalValueFields?: ColumnCellAdditionalValueFields;
  handleAddItem?: (row: RecordItem) => void;
};

const GroupCell = ({
  count,
  row,
  col,
  rowDensityType,
  className,
  handleEmailClick,
  handleCellDetailActionClick,
  config,
  additionalValueFields
}: GroupCellProps) => {
  return (
    <div className="flex w-full items-center justify-between gap-x-2">
      <div>
        <div className="flex flex-col gap-y-0.5">
          {!config?.additionalConfig?.hideGroupLabel && (
            <span className="text-secondary text-[9px] uppercase tracking-widest">{col.header || col.name}</span>
          )}
          <CellGroup
            column={col}
            type={col.type}
            value={row[col.id]}
            className={clsx("w-full", className)}
            classNameContainer="w-full"
            size={getSizeBasedInRowDensity(rowDensityType)}
            onEmailClick={handleEmailClick}
            onCellDetailActionClick={handleCellDetailActionClick}
            isMultiple={col.isMultiple}
            row={row}
            inGrid
            isHeader
            additionalValueFields={additionalValueFields}
          />
        </div>
        {!config?.additionalConfig?.hideGroupCount && (
          <span className="text-secondary text-[13px] font-medium">
            <span className="mr-1 text-[11px] font-normal opacity-75">Count</span>
            {count}
          </span>
        )}
      </div>
    </div>
  );
};

export default GroupCell;
