import { DataGridPremium } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material/styles";

const StyledDataGridPro = styled(DataGridPremium)(({ theme }) => ({
  fontFamily: [
    "font-sans",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  WebkitFontSmoothing: "auto",

  // Here grid can be customized
  ".MuiDataGrid-filterIcon": {},
  ".MuiTablePagination-actions": {
    display: "flex",
  },
  ".MuiInputBase-input": {
    backgroundColor: "transparent",
  },

  ".MuiCheckbox-root svg path": {
    display: "none",
    fill: "white",
  },
  ".MuiDataGrid-aggregationColumnHeaderLabel": {
    display: "none",
  },
  ".MuiCheckbox-root.MuiCheckbox-indeterminate svg path": {
    display: "block",
    d: "path('M6,12 19,12') !important",
    stroke: "white",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2",
  },
  ".MuiCheckbox-root.MuiCheckbox-indeterminate svg": {
    display: "block",
    backgroundColor: "#1890ff",
    borderColor: "#1890ff",
  },

  ".MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg path": {
    display: "block",
    d: "path('M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z') !important",
  },

  ".MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
    backgroundColor: "#1890ff",
    borderColor: "#1890ff",
  },

  ".idCell": {
    borderRight: "none",
  },

  ".formulaIconCell": {
    display: "none",
  },

  ".formulaCell .formulaIconCell": {
    display: "inline-flex !important",
  },

  ".MuiDataGrid-groupingCriteriaCell": {
    height: "36px",
  },
  ".MuiDataGrid-overlayWrapperInner": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  ".MuiInputBase-input:focus": {
    borderColor: "transparent",
    boxShadow: "none",
  },
  ".grouping-row--hide-lines .MuiDataGrid-cell--withRenderer": {
    borderLeft: "0px",
    borderRight: "0px",
  },
  ".MuiDataGrid-columnHeaderTitleContainer .MuiBadge-overlapRectangular": {
    display: "none !important",
  },
  ".MuiDataGrid-pinnedColumns": {
    overflow: "unset",
  },
  ".MuiDataGrid-columnHeader:hover .MuiDataGrid-header-description": {
    display: "block",
  },
}));

export default StyledDataGridPro;
