import { useCallback } from "react";
import isObject from "lodash/isObject";
import { useQueryClient } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";
import { getPagesColumnsByIds } from "lib/adminApi";
import { getFormulaResultForColumn } from "utils/columnUtils";
import { CellType, WRITE_ROLLUP_FORMULAS } from "utils/constants";
import { getColumnOptionsLookupPropAndColumnName } from "lib/utils";
import { getTableDataById } from "lib/supabaseApi";
import useUpdateRecord from "./useUpdateRecord";
import useSchemaState from "./useSchemaState";
import useCurrentUser from "./useCurrentUser";

// This hook updates the write rollup column using table data
// Currently only called from Grid view but can support any view
const useWriteRollupUpdate = () => {
  const supabaseClient = useSupabaseBrowser();
  const { updateRecordAsync } = useUpdateRecord();
  const queryClient = useQueryClient();
  const { schemaInstance } = useSchemaState();
  const currentUser = useCurrentUser();

  const processColumnsAndUpdateTableData = useCallback(
    async ({
      columnsWithWriteRollup,
      allColumns,
      tableData
    }: {
      columnsWithWriteRollup: TableColumnType[];
      allColumns: TableColumnType[];
      tableData: RecordItem[];
    }) => {
      if (!tableData?.length) return;

      for (const writeColData of columnsWithWriteRollup) {
        let updatedValue = 0;
        const { writeRollupConfig } = writeColData;
        if (!writeRollupConfig || !writeRollupConfig.rollupSource?.colId) {
          continue;
        }
        if (writeRollupConfig.formula === WRITE_ROLLUP_FORMULAS.COUNT) {
          updatedValue = tableData.length;
        } else {
          // Get column data that needs to be written
          const finalColValues: string[] = [];
          for (const rowData of tableData) {
            if (writeColData?.isFormula) {
              const formulaResult = getFormulaResultForColumn({
                formulaColumn: writeColData,
                allColumns,
                record: rowData,
                isForm: false
              });
              if (Number.isFinite(formulaResult)) {
                finalColValues.push(formulaResult as string);
              }
            } else if (writeColData.name) {
              finalColValues.push(rowData[writeColData.name]);
            }
          }
          if (finalColValues?.length) {
            finalColValues.forEach((val) => (updatedValue += Number(val)));
          }
        }
        // If updatedValue then update the rollup column linked
        // Current only support level 0 column
        const rollupColToUpdate = allColumns.find((col) => col.id === writeRollupConfig.rollupSource?.colId);
        const idSourceCol = allColumns.find((col) => col.id === writeRollupConfig.idSource);
        if (
          !rollupColToUpdate ||
          !idSourceCol ||
          !rollupColToUpdate.lookupPath ||
          Object.keys(rollupColToUpdate.lookupPath || {}).length > 1 ||
          !Number.isFinite(updatedValue)
        )
          continue;
        const firstLevel = rollupColToUpdate.lookupPath["0"];
        if (
          firstLevel?.lookupColumns?.length &&
          firstLevel?.lookupColumns.includes(writeRollupConfig.rollupSource?.colLookupColumn || "")
        ) {
          const input = {
            [writeRollupConfig.rollupSource?.colLookupColumn || ""]: updatedValue
          };
          // Pick first record from tableData
          const firstRecord = tableData[0];
          const idPropName = idSourceCol?.name ? idSourceCol.name : "";
          let idVal = idPropName ? firstRecord[idPropName] : "";
          if (idSourceCol?.lookupPath) {
            const { finalPropName } = getColumnOptionsLookupPropAndColumnName(
              {
                ...idSourceCol,
                columnOptionsLookUp: undefined
              },
              true
            );
            if (Array.isArray(finalPropName)) {
              let nestedRecord = { ...firstRecord };
              finalPropName.forEach((propName) => {
                if (Array.isArray(nestedRecord[propName])) {
                  nestedRecord = nestedRecord[propName][0];
                } else {
                  nestedRecord = nestedRecord[propName];
                }
              });
              idVal = nestedRecord?.id;
            }
          }
          if (idVal && !isObject(idVal)) {
            input["id"] = idVal;

            const tableName = rollupColToUpdate.lookupPath["0"].lookupTableName;
            // Fetch the value for this record first and update
            // only if it is different from the current value
            const currentRecordValue = await getTableDataById(
              {
                tableName,
                id: idVal,
                columns: [
                  {
                    id: "write_rollup_col",
                    header: "Write Rollup Col",
                    name: writeRollupConfig.rollupSource?.colLookupColumn || "",
                    type: CellType.TEXT
                  }
                ],

                source: "useWriteRollupUpdate",
                organizationId: currentUser?.org_id || ""
              },
              supabaseClient,
              undefined,
              schemaInstance?.extendedSchema
            );

            if (currentRecordValue?.data?.length) {
              const firstRecord = currentRecordValue.data[0];
              if (
                firstRecord?.[writeRollupConfig.rollupSource?.colLookupColumn || ""] ===
                input[writeRollupConfig.rollupSource?.colLookupColumn || ""]
              ) {
                queryClient.invalidateQueries({
                  queryKey: ["table", tableName]
                });
                queryClient.refetchQueries({
                  queryKey: ["table", tableName]
                });
                continue;
              }
            }

            const updatedData = await updateRecordAsync({
              tableName,
              input
            });
            if (updatedData?.error) {
              console.log("@@ Error update write roll up ", updatedData?.error);
            } else {
              queryClient.invalidateQueries({
                queryKey: ["table", tableName]
              });
              queryClient.refetchQueries({
                queryKey: ["table", tableName]
              });
            }
          }
        }
      }
    },
    [updateRecordAsync, queryClient, supabaseClient, schemaInstance?.extendedSchema, currentUser?.org_id]
  );

  // This method is called by a write rollup notification
  // It recieves the Write rollup column ID, the page id of this column and the source column id for the write rollup
  const getWriteRollupTableDataAndUpdate = useCallback(
    async (writeRollupColumnId: string, pageId: string, sourceId: string) => {
      if (!writeRollupColumnId || !pageId || !sourceId) return;
      // Fetch page data to get all columns
      const pageData = await getPagesColumnsByIds({ supabaseClient, pageIds: [pageId] });
      if (pageData?.data?.length) {
        const finalPageData = pageData.data[0];
        const { columns } = finalPageData;
        const writeRollupCol = columns?.find((col) => col.id === writeRollupColumnId);
        const { writeRollupConfig } = writeRollupCol || {};
        if (!writeRollupCol?.name || !writeRollupConfig?.idSource || !columns?.length) {
          return;
        }
        const idSourceCol = columns.find((col) => col.id === writeRollupConfig.idSource);
        if (!idSourceCol?.name) return;
        // Fetch all data for this table with idSourceCol as sourceId value
        // We fetch all basic columns as we may need to process the formula columns
        const { data } = await supabaseClient.from(finalPageData.table_name).select("*").eq(idSourceCol.name, sourceId);
        if (data?.length) {
          await processColumnsAndUpdateTableData({
            columnsWithWriteRollup: [writeRollupCol],
            allColumns: columns,
            tableData: data
          });
        }
      }
    },
    [processColumnsAndUpdateTableData, supabaseClient]
  );

  return {
    processColumnsAndUpdateTableData,
    getWriteRollupTableDataAndUpdate
  };
};

export default useWriteRollupUpdate;
