import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { useQuery } from "@tanstack/react-query";

const useIsParentPage = (pageId: string) => {
  const supabaseClient = useSupabaseBrowser();

  const { data: childPages } = useQuery({
    queryKey: ["child-pages", pageId],
    queryFn: async () => {
      const { data } = await supabaseClient.from("pages").select("id").eq("parent_id", pageId);

      return data;
    },
    enabled: !!pageId,
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    gcTime: Infinity
  });

  return {
    isParentPage: !!childPages?.length
  };
};

export default useIsParentPage;
