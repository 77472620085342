import { useEffect, useState } from "react";

const useHasScrollbar = () => {
  const [hasScrollBar, setHasScrollBar] = useState(false);

  useEffect(() => {
    const outer = document.getElementById("outer");
    const inner = document.getElementById("inner");

    if (!outer || !inner) return;

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    if (scrollbarWidth > 0) {
      setHasScrollBar(true);
    }
  }, []);

  return hasScrollBar;
};

export default useHasScrollbar;
