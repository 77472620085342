import clsx from "clsx";
import { ExpandIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import ContentEditable from "react-contenteditable";
import dynamic from "next/dynamic";

import { LexicalEditor } from "lexical";
import IconButton from "components/IconButton";
import { IconButtonSize } from "components/IconButton/utils";
import PopoverWrapper from "components/PopoverWrapper";
import Skeleton from "components/Skeleton";
import { CellRules } from "types/baTypes";
import { classNameError } from "../utils";
import useTextareaAutoHeight from "./hooks/useTextareaAutoHeight";

const LexicalTextEditor = dynamic(() => import("components/LexicalTextEditor"), { ssr: false });

type TextCellGroupProps = {
  value: string;
  inForm?: boolean;
  onEdit?: (value: string, additionalValues?: { textContent?: string }) => void;
  isLoading?: boolean;
  rules?: CellRules;
  hasRequiredError?: boolean;
  onExit?: () => void;
  inList?: boolean;
  autoFocus?: boolean;
  inGrid?: boolean;
  inCard?: boolean;
  minWidth?: number;
  showTextarea?: boolean;
  onLexicalInitState?: () => void;
};

const TextCellGroup = ({
  value: initialValue = "",
  onEdit,
  inForm = false,
  isLoading = false,
  hasRequiredError = false,
  onExit,
  inList = false,
  autoFocus = false,
  inGrid = false,
  inCard = false,
  minWidth = 0,
  showTextarea = false,
  onLexicalInitState
}: TextCellGroupProps) => {
  const [value, setValue] = useState(initialValue);
  const [errorState, setErrorState] = useState(false);
  const [isEditing, setIsEditing] = useState(!inForm);
  const [isExpanded, setIsExpanded] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const editorRef = useRef<LexicalEditor | undefined>(undefined);

  // only allow this in List
  useTextareaAutoHeight(inList ? textareaRef : undefined);

  // We are using a ref to set the edit value for content editable
  // Issue ref: https://github.com/lovasoa/react-contenteditable/issues/161
  const text = useRef(initialValue || "");

  useEffect(() => {
    setErrorState(hasRequiredError);
  }, [hasRequiredError]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (inForm) {
      setIsEditing(true);
    }
  }, [inForm]);

  const handleOnChange = useCallback(
    (newValue: string, editor?: LexicalEditor) => {
      setValue(newValue);
      setErrorState(false);
      text.current = newValue;
      editorRef.current = editor;
      const additionalValues = {
        textContent: editor?.getRootElement()?.textContent || ""
      };
      if (inGrid) {
        onEdit?.(newValue, additionalValues);
      } else if (inForm || inCard) {
        onEdit?.(newValue, additionalValues);
      }
    },
    [inCard, inForm, inGrid, onEdit]
  );

  const handleOnBlur = useCallback(() => {
    const additionalValues = {
      textContent: editorRef?.current?.getRootElement()?.textContent || ""
    };
    if (inGrid) {
      onEdit?.(text.current, additionalValues);
      onExit?.();
    } else if (inList || inCard) {
      onEdit?.(value, additionalValues);
      onExit?.();
    }
  }, [inCard, inGrid, inList, onEdit, onExit, value]);

  const renderEditor = useCallback(
    (className?: string) => {
      if (inGrid) {
        return (
          <ContentEditable
            className="h-full overflow-y-auto px-[18px] py-4 outline-none scrollbar-hide focus:block"
            tagName="div"
            html={value}
            onChange={(e) => {
              text.current = e.target.value;
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onKeyUp={(e) => e.stopPropagation()}
            onBlur={handleOnBlur}
            autoFocus={autoFocus}
          />
        );
      }

      if (showTextarea) {
        return (
          <textarea
            ref={textareaRef}
            onChange={(e) => (inForm ? handleOnChange(e.target.value) : setValue(e.target.value))}
            onBlur={handleOnBlur}
            className={clsx(
              className,
              "text-primary relative flex min-h-[56px] w-full items-center rounded-lg border bg-transparent px-3 py-[11px] focus:outline-none",
              inForm
                ? "border-transparent !bg-gray-100 text-md transition-colors hover:bg-gray-150 focus:border-transparent focus:!bg-transparent focus:ring-2 focus:ring-primary-700 dark:!bg-gray-600/60 dark:hover:bg-gray-600 dark:focus:bg-gray-600 dark:focus:ring-primary-dark-700"
                : "border-transparent text-sm focus:text-md",
              !inForm && "hover:bg-neutral-300 focus:!bg-transparent hover:dark:bg-neutral-dark-300",
              inGrid && "border-0 border-transparent focus:ring-0"
            )}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onKeyPress={(e) => {
              e.stopPropagation();
            }}
            autoFocus={autoFocus}
          >
            {value}
          </textarea>
        );
      }

      return (
        <LexicalTextEditor
          className={clsx(
            className,
            errorState && inForm
              ? classNameError
              : "focus-within:ring-2 focus-within:ring-primary-700 dark:focus-within:ring-primary-dark-700"
          )}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          initialValue={value}
          autoFocus={autoFocus || inList}
          isEditing={isEditing}
          showExpandViewIcon
          onLexicalInitState={onLexicalInitState}
        />
      );
    },
    [
      autoFocus,
      errorState,
      handleOnBlur,
      handleOnChange,
      inForm,
      inGrid,
      inList,
      isEditing,
      showTextarea,
      value,
      onLexicalInitState
    ]
  );

  if (isLoading) {
    return <Skeleton className="h-3 max-w-full" />;
  }

  if (inGrid) {
    return (
      <div className="relative h-full w-full">
        {renderEditor("max-h-[250px] max-w-[400px]")}

        {isExpanded && (
          <LexicalTextEditor
            onChange={(newValue, editor) => {
              text.current = newValue;
              editorRef.current = editor;
            }}
            onSave={handleOnBlur}
            initialValue={text.current}
            autoFocus={autoFocus}
            isEditing={isEditing}
            isExpanded={isExpanded}
            onCloseExpanded={() => setIsExpanded(false)}
            onlyShowModal
          />
        )}

        <IconButton
          onClick={() => setIsExpanded(true)}
          icon={ExpandIcon}
          size={IconButtonSize.XS}
          className="!absolute bottom-1 right-1 rounded-full !bg-white"
        />
      </div>
    );
  }

  if (inCard) {
    return (
      <PopoverWrapper
        classNameContainer="absolute top-0 left-0"
        className="max-w-[400px]"
        minWidth={minWidth < 400 ? minWidth : 400}
      >
        {renderEditor("max-h-[250px] max-w-[400px]")}
      </PopoverWrapper>
    );
  }

  return renderEditor();
};

export default TextCellGroup;
