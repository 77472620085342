import { useState, useEffect, useCallback, useRef } from "react";
import clsx from "clsx";
import { useIMask } from "react-imask";
import { LucideIcon } from "lucide-react";

import { CellRules } from "types/baTypes";
import { classNameError, sanitizeValue } from "../utils";

type InputCellGroupProps = {
  value: string;
  prefix?: LucideIcon;
  suffix?: LucideIcon;
  onEdit?: (value: string) => void;
  inForm?: boolean;
  inList?: boolean;
  init?: (value: string) => string;
  rules?: CellRules;
  hasRequiredError?: boolean;
  type?: string;
  pattern?: string;
  title?: string;
  maskOptions: any;
  ignoreValidation?: boolean;
  autoFocus?: boolean;
  onExit?: () => void;
  isReadOnly?: boolean;
  inCard?: boolean;
  inGrid?: boolean;
  isFocused?: boolean;
  isFormula?: boolean;
  leadingAddOn?: string;
  isInFilters?: boolean;
  transform?: (value: string) => string;
  transformBeforeToSave?: (value: string) => string;
};

const InputCellGroup = ({
  value: initialValue = "",
  onEdit,
  prefix: Prefix,
  suffix: Suffix,
  inForm = false,
  inList = false,
  inCard = false,
  inGrid = false,
  init = (value) => value,
  rules,
  hasRequiredError = false,
  type = "text",
  pattern,
  title,
  maskOptions,
  ignoreValidation = false,
  autoFocus = false,
  onExit,
  isReadOnly,
  isFocused,
  isFormula = false,
  isInFilters = false,
  leadingAddOn = "",
  transform = (value) => value,
  transformBeforeToSave = (value) => value
}: InputCellGroupProps) => {
  const [errorState, setErrorState] = useState(false);
  const initValueAssignedRef = useRef(false);
  const [isFocus, setFocus] = useState(false);
  const {
    ref: inputRef,
    value,
    setValue,
    unmaskedValue
  } = useIMask(
    {
      ...maskOptions
    },
    {
      onComplete: (value: string, masked: any) => {
        const isValid = ignoreValidation || (inputRef.current as any)?.checkValidity();

        if (rules?.required && !value) {
          setErrorState(true);
        } else {
          setErrorState(!isValid);
        }

        if (masked.unmaskedValue !== initialValue?.toString() && inForm && !isInFilters) {
          onEdit?.(masked.unmaskedValue);
        }
      }
    }
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isValid = ignoreValidation || e.target?.checkValidity();

      e.target.value = transform ? transform(e.target.value) : e.target.value;

      if (!maskOptions?.mask) {
        const sanitizedValue = sanitizeValue(e.target.value);
        setValue(sanitizedValue);

        if (inForm) {
          onEdit?.(transformBeforeToSave(sanitizedValue));
        }

        if (rules?.required && !sanitizedValue) {
          setErrorState(true);
          return;
        } else {
          setErrorState(!isValid);
        }
      }
    },
    [ignoreValidation, transform, maskOptions?.mask, setValue, inForm, rules?.required, onEdit, transformBeforeToSave]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        e.stopPropagation();
        (inputRef.current as any)?.blur();
      }

      if (e.key === "Escape") {
        if ((inForm || inList) && isFocus) {
          e.stopPropagation();
          (inputRef.current as any)?.blur();
        } else if (isFocus) {
          e.stopPropagation();
        }
      }

      if (["J", "j", "K", "k", "O", "o"].includes(e.key)) {
        e.stopPropagation();
      }

      if (inGrid && e.key === "Tab") {
        e.preventDefault();
        (inputRef.current as any)?.blur();
      }

      if (inGrid && e.key === " ") {
        e.stopPropagation();
      }
    },
    [inGrid, inputRef, isFocus, inForm, inList]
  );

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const isValid = ignoreValidation || e.target?.checkValidity();

      if (!isValid) {
        if (inList) {
          setValue(initialValue?.toString() || "");
        }
      }

      if (maskOptions?.mask && unmaskedValue !== initialValue?.toString()) {
        onEdit?.(transformBeforeToSave(unmaskedValue));
      } else if (!maskOptions?.mask && value !== initialValue?.toString()) {
        onEdit?.(transformBeforeToSave(value));
      }
      onExit?.();
      setFocus(false);
    },
    [
      ignoreValidation,
      inList,
      initialValue,
      maskOptions?.mask,
      onEdit,
      onExit,
      setValue,
      transformBeforeToSave,
      unmaskedValue,
      value
    ]
  );

  useEffect(() => {
    if ((isFocus && !inForm) || (isInFilters && initValueAssignedRef.current)) return;
    initValueAssignedRef.current = true;
    if (initialValue && ((inForm && !value) || !inForm)) {
      setValue(init(initialValue?.toString()) || "");
    }
    if (inForm && !initialValue) {
      setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, isFocus, inForm]);

  // Only for form
  useEffect(() => {
    if (!inForm) return;
    if (isFocused) {
      (inputRef.current as any)?.focus({ focusVisible: true });
    } else {
      (inputRef.current as any)?.blur();
    }
  }, [isFocused, inputRef, inForm]);

  useEffect(() => {
    setErrorState(hasRequiredError);
  }, [hasRequiredError]);

  return (
    <div className={clsx("relative flex w-full items-center rounded-lg")}>
      <input
        className={clsx(
          "text-primary relative flex min-h-[56px] w-full items-center rounded-lg border bg-transparent px-3 py-[11px] focus:outline-none",
          inForm
            ? isFormula
              ? "border-separator border"
              : "border-transparent !bg-gray-100 text-md transition-colors hover:bg-gray-150 focus:border-transparent focus:!bg-transparent focus:ring-2 focus:ring-primary-700 dark:!bg-gray-600/60 dark:hover:bg-gray-600 dark:focus:!bg-gray-600 dark:focus:ring-primary-dark-700"
            : "border-transparent text-sm focus:text-md",
          (isFocus || inForm) && !!Prefix && "pl-8",
          (isFocus || inForm) && !!leadingAddOn && "pl-[68px]",
          (isFocus || inForm) && !!Suffix && "pr-8",
          !inForm && "hover:bg-neutral-300 focus:!bg-transparent hover:dark:bg-neutral-dark-300",
          !ignoreValidation && errorState && inForm ? classNameError : " ",
          inGrid && "border-0 border-transparent focus:ring-0"
        )}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={inputRef as any}
        value={value}
        placeholder={!isFocus && !inForm ? "Add" : ""}
        type={type}
        pattern={pattern}
        title={title}
        autoFocus={autoFocus || !inForm}
        required={rules?.required}
        disabled={isReadOnly || isFormula}
      />
      {(isFocus || !!value) && !!leadingAddOn && (
        <div className="absolute left-3">
          <span className="text-md text-gray-600 dark:text-gray-300">{leadingAddOn}</span>
        </div>
      )}
      {(isFocus || inForm) && !!Prefix && (
        <div className="absolute left-3">
          <Prefix className="h-4 w-4" />
        </div>
      )}
      {(isFocus || inForm) && !!Suffix && (
        <div className="absolute right-3">
          <Suffix className="h-4 w-4" />
        </div>
      )}
    </div>
  );
};

export default InputCellGroup;
