import React from "react";

const GroupingCriteriaCollapseIcon = () => {
  return (
    <svg width="16" height="16" fill="none" className="h-4 w-4 text-gray-400 transition-transform">
      <path
        d="M8.67 10.18c-.324.426-1.016.426-1.34 0L4.646 6.665c-.375-.492.012-1.164.67-1.164h5.365c.659 0 1.046.672.671 1.164L8.671 10.18z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

const GroupingCriteriaExpandIcon = () => {
  return (
    <svg width="16" height="16" fill="none" className="h-4 w-4 -rotate-90 text-gray-400 transition-transform">
      <path
        d="M8.67 10.18c-.324.426-1.016.426-1.34 0L4.646 6.665c-.375-.492.012-1.164.67-1.164h5.365c.659 0 1.046.672.671 1.164L8.671 10.18z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export const GroupingCollapseIcon = React.memo(GroupingCriteriaCollapseIcon);

export const GroupingExpandIcon = React.memo(GroupingCriteriaExpandIcon);
